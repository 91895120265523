import React from "react";
import { Link } from "react-router-dom";
import HorizontalLogo from "../../assets/images/Logo-Horizontal.png";
import UserDark from "../../assets/images/icons/user-dark.png";
import LockDark from "../../assets/images/icons/lock-dark.png";
import Button from "../../components/UI/Button/Button";
import Checkbox from "../../components/UI/Checkbox/Checkbox";
import Input from "../../components/UI/Input/Input";
import {withRouter} from "react-router-dom";

const Login = (props) => (
    <div className={props.className}>
        <div className="heading-box">
            <h2 className="light-heading">ADMIN PANEL</h2>
            <img src={HorizontalLogo} alt="Logo" />
        </div>
        <div className="inputbox">
            <Input
                type="email"
                placeholder="Email"
                className="block white"
                icon={UserDark}
            />
            <Input
                type="password"
                placeholder="Password"
                className="block white"
                icon={LockDark}
            />
        </div>
        <div className="actionbox">
            <Button
                onClick={() => props.history.push("/dashboard")}
                className="btn-block btn-lg btn-orange"
            >
                LOGIN
            </Button>
            <div className="bottomarea">
                <Checkbox name="remember" label="Remember me" />
                <Link to="/auth/forget-password">Forget your password?</Link>
            </div>
        </div>
    </div>
);

export default withRouter(Login);
