import React from "react";
import "./Dropdown2.scss";

class Dropdown2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: {},
            isVisible: false,
        };

        this.toggleHanlder = this.toggleHanlder.bind(this);
    }

    selectHandler(selection) {
        this.setState({
            selected: selection,
        });
        this.props.changeHandler(selection);
    }

    componentDidMount() {
        let def;

        const active = this.props.items.find(x => x.active);
        if (active) {
            def = active
        } else {
            def = this.props.items[0]
        }

        this.setState({
            selected: def,
        });
    }

    toggleHanlder() {
        this.setState((state) => ({
            isVisible: !state.isVisible,
        }));
    }

    render() {
        let selection;

        const items = this.props.items.map((item) => {
            return (
                <li
                    key={item.text}
                    onClick={this.selectHandler.bind(this, item)}
                    className={
                        item.value === this.state.selected.value ? "active" : ""
                    }
                >
                    {item.text}
                </li>
            );
        });

        if (!this.state.isVisible) {
            selection = (
                <span className="selected">{this.state.selected.text}</span>
            );
        } else {
            selection = <ul>{items}</ul>;
        }

        return (
            <div
                className={"dropdown2box " + (this.props.orange ? "orange" : "")}
                onClick={this.toggleHanlder}
            >
                <span className="title">{this.props.title} :</span>
                <div className="data">{selection}</div>
            </div>
        );
    }
}

export default Dropdown2;
