import React from "react";
import "./StreamIndex.scss";
import Layout from "../../../components/Layout/Layout";
import SearchBox from "../../../components/SearchBox/SearchBox";
import Tab from "../../../components/UI/Tab/Tab";
import Video1 from "../../../assets/images/thumbnails/video-1.jpg";
import Video2 from "../../../assets/images/thumbnails/video-2.jpg";
import Video3 from "../../../assets/images/thumbnails/video-3.jpg";
import Video from "../../../components/Video/Video";
import ScrollBox from "../../../components/ScrollBox/ScrollBox";

class StreamIndex extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            streams: [
                {
                    id: 1,
                    title: "Linus tech Tips on Twitter",
                    view: 1500,
                    amount: 800,
                    thumbnail: Video1,
                    created_at: "2019-10-07",
                },
                {
                    id: 2,
                    title: "Linus tech Tips on Twitter",
                    view: 1500,
                    amount: 800,
                    thumbnail: Video2,
                    created_at: "2019-10-07",
                },
                {
                    id: 3,
                    title: "Linus tech Tips on Twitter",
                    view: 1500,
                    amount: 800,
                    thumbnail: Video3,
                    created_at: "2019-10-07",
                },
                {
                    id: 4,
                    title: "Linus tech Tips on Twitter",
                    view: 1500,
                    amount: 800,
                    thumbnail: Video2,
                    created_at: "2019-10-07",
                },
                {
                    id: 5,
                    title: "Linus tech Tips on Twitter",
                    view: 1500,
                    amount: 800,
                    thumbnail: Video3,
                    created_at: "2019-10-07",
                },
                {
                    id: 6,
                    title: "Linus tech Tips on Twitter",
                    view: 1500,
                    amount: 800,
                    thumbnail: Video1,
                    created_at: "2019-10-07",
                },
                {
                    id: 7,
                    title: "Linus tech Tips on Twitter",
                    view: 1500,
                    amount: 800,
                    thumbnail: Video2,
                    created_at: "2019-10-07",
                },
                {
                    id: 8,
                    title: "Linus tech Tips on Twitter",
                    view: 1500,
                    amount: 800,
                    thumbnail: Video3,
                    created_at: "2019-10-07",
                },
                {
                    id: 9,
                    title: "Linus tech Tips on Twitter",
                    view: 1500,
                    amount: 800,
                    thumbnail: Video2,
                    created_at: "2019-10-07",
                },
                {
                    id: 10,
                    title: "Linus tech Tips on Twitter",
                    view: 1500,
                    amount: 800,
                    thumbnail: Video3,
                    created_at: "2019-10-07",
                },
                {
                    id: 11,
                    title: "Linus tech Tips on Twitter",
                    view: 1500,
                    amount: 800,
                    thumbnail: Video2,
                    created_at: "2019-10-07",
                },
                {
                    id: 12,
                    title: "Linus tech Tips on Twitter",
                    view: 1500,
                    amount: 800,
                    thumbnail: Video3,
                    created_at: "2019-10-07",
                },
                {
                    id: 13,
                    title: "Linus tech Tips on Twitter",
                    view: 1500,
                    amount: 800,
                    thumbnail: Video3,
                    created_at: "2019-10-07",
                },
            ],
        };

        this.tabChangeHandler = this.tabChangeHandler.bind(this);
    }

    tabChangeHandler(value){
        //
    }

    render() {
        const streams = this.state.streams.map((stream) => (
            <Video key={stream.id} className="small" video={stream} />
        ));

        return (
            <Layout>
                <Tab onChange={this.tabChangeHandler} large items={['Streams', 'Upload']} />
                <div id="streams">
                    <SearchBox className="small" width="30rem" />
                    <ScrollBox height="48rem">
                        <div className="stream-container">{streams}</div>
                    </ScrollBox>
                </div>
            </Layout>
        );
    }
}

export default StreamIndex;
