import React from "react";
import { Link } from "react-router-dom";
import "./SidebarLink.scss";
import { withRouter } from "react-router-dom";

const SidebarLink = (props) => {
    const isActive = props.match.path === props.target;
    return (
        <Link
            to={props.target}
            className={"sidebar-link " + (isActive ? "active" : "")}
        >
            <div className="icon">
                <img
                    src={isActive ? props.activeIcon : props.icon}
                    alt={props.text}
                />
            </div>
            <span>{props.text}</span>
        </Link>
    );
};

export default withRouter(SidebarLink);
