import React from "react";
import './Checkbox.scss';

const Checkbox = (props) => (
    <div className="input-group checkbox">
        <input name={props.name} id={props.name} type="checkbox"/>
        <label htmlFor={props.name}>{props.label}</label>
    </div>
);

export default Checkbox;
