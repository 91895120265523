import React from "react";
import './FillGraph.scss';

const FillGraph = (props) => {
    return (
        <div className="fillgraph">
            <div className="flag">
                <img src={props.flag} className="img-block" alt="flag" />
            </div>
            <div className="data">
                <span>{props.text}</span>
                <div className="bar">
                    <span className="fill" style={{ width: props.fill+'%' }}></span>
                </div>
            </div>
        </div>
    );
};

export default FillGraph;
