import React from "react";
import Topbar from "../Topbar/Topbar";
import Sidebar from "../Sidebar/Sidebar";
import "./Layout.scss";

const Layout = (props) => {
    let overlay;

    if (props.overlay) {
        overlay = <div className="overlay"></div>;
    }

    return (
        <div className="container">
            <div className="sidebar-container">
                <Sidebar />
            </div>
            <div className="main-container">
                <div className="topbar-container">
                    <Topbar />
                </div>
                <main>
                    {props.children}
                    {overlay}
                </main>
            </div>
        </div>
    );
};

export default Layout;
