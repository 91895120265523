import React from "react";
import "./AdvanceDatabox.scss";
import Dropdown2 from "../Dropdown2/Dropdown2";
import DropdownTrigger from "../DropdownTrigger/DropdownTrigger";

class AdvanceDatabox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isDropdownVisible: false,
        };
    }
    render() {
        return (
            <div className="advance-databox">
                <div className="dropdown-container">
                    <DropdownTrigger />
                </div>
                <div className="left">
                    <h3 className="title">{this.props.title}</h3>
                    <div className="dropdown2-container">
                        <Dropdown2
                            title={this.props.dropdown.title}
                            changeHandler={this.props.updateData}
                            items={this.props.dropdown.items}
                        />
                    </div>
                </div>
                <div className="right">
                    <div className="value">{this.props.value}</div>
                </div>
            </div>
        );
    }
}

export default AdvanceDatabox;
