import React from "react";
import './Label.scss';

const Label = (props) => {
    return (
        <div onClick={props.onClick} className={"label " + (props.className ? props.className : '')}>
            <img src={props.icon} alt="icon" className="icon" />
            <span className="text">{props.text}</span>
        </div>
    );
};

export default Label;
