import React from "react";
import "./Tab.scss";

class Tab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: props.items[0],
        };
    }

    changeHanlder(item) {
        this.setState({
            active: item,
        });
        this.props.onChange(item);
    }

    render() {
        return (
            <div className={"tabbox " + (this.props.large ? "large" : "")}>
                {this.props.items.map((item, index) => (
                    <div
                        key={index}
                        onClick={this.changeHanlder.bind(this, item)}
                        className={
                            "item " +
                            (this.state.active === item ? "active" : "")
                        }
                    >
                        {item}
                    </div>
                ))}
            </div>
        );
    }
}

export default Tab;
