import React from "react";
import "./NotificationIndex.scss";
import Layout from "../../../components/Layout/Layout";
import Notification from "../../../components/Notification/Notification";
import ScrollBox from "../../../components/ScrollBox/ScrollBox";
import Button from "../../../components/UI/Button/Button";
import {withRouter} from "react-router-dom";

class Notifications extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lastNotifications: [
                {
                    id: 1,
                    text:
                        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis dolorum laboriosam recusandae quos ea culpa consectetur asperiores, ipsa obcaecati sapiente est explicabo voluptate voluptatibus ab quisquam iusto aspernatur illo.",
                    channel: "Streamers",
                },
                {
                    id: 2,
                    text:
                        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis dolorum laboriosam recusandae quos ea culpa consectetur asperiores, ipsa obcaecati sapiente est explicabo voluptate voluptatibus ab quisquam iusto aspernatur illo.",
                    channel: "All",
                },
                {
                    id: 3,
                    text:
                        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis dolorum laboriosam recusandae quos ea culpa consectetur asperiores, ipsa obcaecati sapiente est explicabo voluptate voluptatibus ab quisquam iusto aspernatur illo.",
                    channel: "Challengers",
                },
            ],
            pendingNotifications: [
                {
                    id: 1,
                    text:
                        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis dolorum laboriosam recusandae quos ea culpa consectetur asperiores, ipsa obcaecati sapiente est explicabo voluptate voluptatibus ab quisquam iusto aspernatur illo.",
                    pending: true,
                    date: "2019-12-23",
                },
                {
                    id: 2,
                    text:
                        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis dolorum laboriosam recusandae quos ea culpa consectetur asperiores, ipsa obcaecati sapiente est explicabo voluptate voluptatibus ab quisquam iusto aspernatur illo.",
                    pending: true,
                    date: "2019-12-23",
                },
                {
                    id: 3,
                    text:
                        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis dolorum laboriosam recusandae quos ea culpa consectetur asperiores, ipsa obcaecati sapiente est explicabo voluptate voluptatibus ab quisquam iusto aspernatur illo.",
                    pending: true,
                    date: "2019-12-23",
                },
                {
                    id: 4,
                    text:
                        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis dolorum laboriosam recusandae quos ea culpa consectetur asperiores, ipsa obcaecati sapiente est explicabo voluptate voluptatibus ab quisquam iusto aspernatur illo.",
                    pending: true,
                    date: "2019-12-23",
                },
            ],
            UsersModalVisible: false,
        };
    }

    render() {
        const lastNotifications = this.state.lastNotifications.map(
            (notification) => (
                <Notification
                    key={notification.id}
                    notification={notification}
                />
            )
        );

        const pendingNotifications = this.state.pendingNotifications.map(
            (notification) => (
                <Notification
                    key={notification.id}
                    notification={notification}
                />
            )
        );

        return (
            <Layout>
                <div id="notifications">
                    <div className="top">
                        <div className="left">
                            <h2 className="title aqua">
                                Last Push Notification
                            </h2>
                            <ScrollBox
                                className="hidden"
                                height="calc(100vh - 45rem)"
                            >
                                <div className="notification-container">
                                    {lastNotifications}
                                </div>
                            </ScrollBox>
                        </div>
                        <div className="right">
                            <h2 className="title aqua">
                                Pending Push Notification
                            </h2>
                            <ScrollBox
                                className="hidden"
                                height="calc(100vh - 45rem)"
                            >
                                <div className="notification-container">
                                    {pendingNotifications}
                                </div>
                            </ScrollBox>
                        </div>
                    </div>
                    <div className="bottom">
                        <Button onClick={() => this.props.history.push('/notifications/new')} className="btn-orange btn-giant">
                            Send Push Notification
                        </Button>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default withRouter(Notifications);
