import React from "react";
import UserPhoto from "../UserPhoto/UserPhoto";
import CheckGreen from "../../assets/images/icons/CheckGreen.png";
import LockIcon from "../../assets/images/icons/lock.png";
import MessageIcon from "../../assets/images/icons/message.png";
import BlockIcon from "../../assets/images/icons/block.png";
import Dropdown from "../Dropdown/Dropdown";
import "./UserBar.scss";
import DropdownTrigger from "../DropdownTrigger/DropdownTrigger";
import Tab from "../UI/Tab/Tab";

class UserBar extends React.Component {
    constructor() {
        super();
        this.state = {
            DropdownVisible: false,
            tabActive: 'Streams'
        };

        this.closeDropdownHandler = this.closeDropdownHandler.bind(this);
        this.openDropdownHandler = this.openDropdownHandler.bind(this);
        this.tabChangeHandler = this.tabChangeHandler.bind(this);
    }

    closeDropdownHandler() {
        this.setState({
            DropdownVisible: false,
        });
    }

    openDropdownHandler() {
        this.setState({
            DropdownVisible: true,
        });
    }

    tabChangeHandler(value) {
        this.setState({
            tabActive: value
        })
    }

    render() {
        let verifiedSign, dropdown, largeVerifiedSign, tabs;

        tabs = (
            <Tab
                onChange={this.tabChangeHandler}
                items={["Streams", "Upload", "Challenges"]}
            />
        );

        if (this.props.user.isEmailVerified && this.props.bigVerified) {
            largeVerifiedSign = (
                <div className="right">
                    <div className="verified">
                        <img src={CheckGreen} alt="check" />
                        <span>Verified email</span>
                    </div>
                </div>
            );
        } else if (this.props.user.isEmailVerified) {
            verifiedSign = (
                <div className="verified">
                    <img className="img-block" src={CheckGreen} alt="check" />
                </div>
            );
        }

        if (this.state.DropdownVisible) {
            dropdown = (
                <Dropdown
                    className="light"
                    CloseHandler={this.closeDropdownHandler}
                    items={[
                        {
                            text: "Reset Password",
                            icon: LockIcon,
                        },
                        {
                            text: "Send Email",
                            icon: MessageIcon,
                        },
                        {
                            text: "Block User",
                            icon: BlockIcon,
                        },
                    ]}
                />
            );
        }

        return (
            <div className="userbar">
                <div className="left">
                    <div className="userphoto-container">
                        <UserPhoto
                            image={this.props.user.photo}
                            flag={this.props.user.flag}
                            isOnline={this.props.user.isOnline}
                        />
                    </div>
                    <h3 className="light-heading name">
                        {verifiedSign}
                        {this.props.user.name}
                    </h3>
                    <span className="email">{this.props.user.email}</span>
                </div>
                {tabs}
                {largeVerifiedSign}
                <div className="bar-dropdown">
                    <DropdownTrigger onClick={this.openDropdownHandler} />
                    <div className="dropdown-container">{dropdown}</div>
                </div>
            </div>
        );
    }
}

export default UserBar;
