import React from "react";
import "./SearchBox.scss";
import SearchIcon from "../../assets/images/icons/SearchIcon.png";

const SearchBox = (props) => {
    let style;

    if (props.width){
        style = {width: props.width}
    }

    return (
        <div style={style} className={"searchbox " + (props.className ? props.className : "")}>
            <input type="search" placeholder="Search" />
            <div className="icon">
                <img src={SearchIcon} alt="Search Icon" />
            </div>
        </div>
    );
};

export default SearchBox;
