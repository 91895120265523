import React from "react";
import "./Dashboard.scss";
import Layout from "../../components/Layout/Layout";
import AreaGraph from "../../components/Graphs/AreaGraph/AreaGraph";
import USAFlag from "../../assets/images/icons/USAFlag.png";
import CanadaFlag from "../../assets/images/icons/CanadaFlag.png";
import WinnerIcon from "../../assets/images/icons/WinnerIcon.png";
import FillGraph from "../../components/Graphs/FillGraph/FillGraph";
import AdvanceDatabox from "../../components/AdvanceDatabox/AdvanceDatabox";
import Title from "./Title/Title";
import RankBox from "../../components/RankBox/RankBox";
import Photo1 from "../../assets/images/users/user-1.jpg";
import Photo2 from "../../assets/images/users/user-2.jpg";
import Carousel from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";
import Video1 from "../../assets/images/thumbnails/video-1.jpg";
import Video from "../../components/Video/Video";
import Dropdown2 from "../../components/Dropdown2/Dropdown2";
import { withRouter } from "react-router-dom";

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            graphData: {
                users: [
                    {
                        name: "Page A",
                        uv: 4000,
                        pv: 2400,
                        amt: 2400,
                    },
                    {
                        name: "Page B",
                        uv: 3000,
                        pv: 1398,
                        amt: 2210,
                    },
                    {
                        name: "Page C",
                        uv: 2000,
                        pv: 9800,
                        amt: 2290,
                    },
                    {
                        name: "Page D",
                        uv: 2780,
                        pv: 3908,
                        amt: 2000,
                    },
                    {
                        name: "Page E",
                        uv: 1890,
                        pv: 4800,
                        amt: 2181,
                    },
                    {
                        name: "Page F",
                        uv: 2390,
                        pv: 3800,
                        amt: 2500,
                    },
                    {
                        name: "Page G",
                        uv: 3490,
                        pv: 4300,
                        amt: 2100,
                    },
                    {
                        name: "Page G",
                        uv: 4000,
                        pv: 5000,
                        amt: 2100,
                    },
                ],
                country: {
                    first: {
                        flag: USAFlag,
                        value: "10K",
                    },
                    others: [
                        {
                            flag: USAFlag,
                            value: 5100,
                        },
                        {
                            flag: CanadaFlag,
                            value: 4952,
                        },
                        {
                            flag: USAFlag,
                            value: 3528,
                        },
                    ],
                },
            },
            challenges: 520,
            livestreams: 248,
            topUsers: [
                {
                    id: 1,
                    rank: 1,
                    name: "Linus Tech",
                    location: "Canada",
                    views: 6492,
                    revenue: "12.5K",
                    photo: Photo1,
                },
                {
                    id: 2,
                    rank: 2,
                    name: "Linus Tech",
                    location: "Canada",
                    views: 6492,
                    revenue: "12.5K",
                    photo: Photo1,
                },
                {
                    id: 3,
                    rank: 3,
                    name: "Linus Tech",
                    location: "Canada",
                    views: 6492,
                    revenue: "12.5K",
                    photo: Photo1,
                },
                {
                    id: 4,
                    rank: 4,
                    name: "Linus Tech",
                    location: "Canada",
                    views: 6492,
                    revenue: "12.5K",
                    photo: Photo1,
                },
                {
                    id: 5,
                    rank: 5,
                    name: "Linus Tech",
                    location: "Canada",
                    views: 6492,
                    revenue: "12.5K",
                    photo: Photo1,
                },
            ],
            topChallenges: [
                {
                    id: 1,
                    rank: 1,
                    title: "Create the best Vlog in New York",
                    views: "25.3K",
                    donation: "2.1K",
                    users: [
                        {
                            photo: Photo1,
                        },
                    ],
                },
                {
                    id: 2,
                    rank: 2,
                    title: "Create the best Vlog in New York",
                    views: "25.3K",
                    donation: "2.1K",
                    users: [
                        {
                            photo: Photo1,
                        },
                        {
                            photo: Photo2,
                        },
                    ],
                },
                {
                    id: 3,
                    rank: 3,
                    title: "Create the best Vlog in New York",
                    views: "25.3K",
                    donation: "2.1K",
                    users: [
                        {
                            photo: Photo1,
                        },
                        {
                            photo: Photo2,
                        },
                        {
                            photo: Photo1,
                        },
                    ],
                },
            ],
            topStreamVideos: [
                {
                    id: 1,
                    title: "Linus tech Tips on Twitter",
                    view: 1500,
                    amount: 800,
                    thumbnail: Video1,
                    user: 1,
                },
                {
                    id: 2,
                    title: "Linus tech Tips on Twitter",
                    view: 1500,
                    amount: 800,
                    thumbnail: Video1,
                    user: 1,
                },
                {
                    id: 3,
                    title: "Linus tech Tips on Twitter",
                    view: 1500,
                    amount: 800,
                    thumbnail: Video1,
                    user: 1,
                },
                {
                    id: 4,
                    title: "Linus tech Tips on Twitter",
                    view: 1500,
                    amount: 800,
                    thumbnail: Video1,
                    user: 1,
                },
            ],
            topChallengeVideos: [
                {
                    id: 1,
                    title: "Linus tech Tips on Twitter",
                    view: 1500,
                    amount: 800,
                    thumbnail: Video1,
                    user: 1,
                },
                {
                    id: 2,
                    title: "Linus tech Tips on Twitter",
                    view: 1500,
                    amount: 800,
                    thumbnail: Video1,
                    user: 1,
                },
                {
                    id: 3,
                    title: "Linus tech Tips on Twitter",
                    view: 1500,
                    amount: 800,
                    thumbnail: Video1,
                    user: 1,
                },
                {
                    id: 4,
                    title: "Linus tech Tips on Twitter",
                    view: 1500,
                    amount: 800,
                    thumbnail: Video1,
                    user: 1,
                },
            ],
        };
    }

    updateUserNumberGraph(data) {
        //
    }

    updateRevenueGraph(data) {
        //
    }

    updateUserCountryGraph(data) {
        //
    }

    render() {
        const topUsers = this.state.topUsers.map((user) => (
            <RankBox
                key={user.id}
                title={user.name}
                subtitle={user.location}
                data1={{ title: "views", value: user.views }}
                data2={{ title: "Revenue", value: "$" + user.revenue }}
                onClick={() => this.props.history.push(`/users/${user.id}`)}
                photo={Photo1}
                rank={user.rank}
            />
        ));

        const topChallenges = this.state.topChallenges.map(
            ({ id, title, rank, views, donation, users }) => (
                <RankBox
                    key={id}
                    onClick={() => this.props.history.push(`/challenges/${id}`)}
                    title={title}
                    data1={{ title: "views", value: views }}
                    data2={{ title: "Donation", value: "$" + donation }}
                    photo={users}
                    rank={rank}
                />
            )
        );

        const topStreamVideos = this.state.topStreamVideos.map((video) => (
            <Video
                key={video.id}
                noViewersModal
                className="small"
                video={video}
            />
        ));

        const topChallengeVideos = this.state.topChallengeVideos.map(
            (video) => (
                <Video
                    key={video.id}
                    noViewersModal
                    className="small"
                    video={video}
                />
            )
        );

        const countries = this.state.graphData.country.others.map((country) => (
            <FillGraph
                key={country.value}
                text={country.value + " users"}
                fill={(country.value / 10000) * 100}
                flag={country.flag}
            />
        ));
        return (
            <Layout>
                <div id="dashboard">
                    <div className="graphs">
                        <AreaGraph
                            badge="12K"
                            title="Number of users"
                            data={this.state.graphData.users}
                            updateData={this.updateUserNumberGraph}
                            color="#40B1CE"
                            dropdown={{
                                title: "Period",
                                items: [
                                    {
                                        text: 'All',
                                        value: 'all'
                                    },
                                    {
                                        text: 'Daily',
                                        value: 'daily',
                                        active: true
                                    },
                                    {
                                        text: "Weekly",
                                        value: "weekly",
                                    },
                                    {
                                        text: "Monthly",
                                        value: "monthly",
                                    },
                                    {
                                        text: "Yearly",
                                        value: "yearly",
                                    },
                                ],
                            }}
                        />
                        <AreaGraph
                            badge="145K"
                            prefix="$"
                            title="Revenue"
                            data={this.state.graphData.users}
                            updateData={this.updateRevenueGraph}
                            color="#F87E5F"
                            orange
                            dropdown={{
                                title: "Period",
                                items: [
                                    {
                                        text: 'All',
                                        value: 'all'
                                    },
                                    {
                                        text: 'Daily',
                                        value: 'daily',
                                        active: true
                                    },
                                    {
                                        text: "Weekly",
                                        value: "weekly",
                                    },
                                    {
                                        text: "Monthly",
                                        value: "monthly",
                                    },
                                    {
                                        text: "Yearly",
                                        value: "yearly",
                                    },
                                ],
                            }}
                        />
                        <div className="graph">
                            <div className="title">
                                Numbers of users by country
                            </div>
                            <div className="dropdown-container">
                                <Dropdown2
                                    title="Period"
                                    changeHandler={this.updateUserCountryGraph}
                                    items={[
                                        {
                                            text: 'All',
                                            value: 'all'
                                        },
                                        {
                                            text: 'Daily',
                                            value: 'daily',
                                            active: true
                                        },
                                        {
                                            text: "Weekly",
                                            value: "weekly",
                                        },
                                        {
                                            text: "Monthly",
                                            value: "monthly",
                                        },
                                        {
                                            text: "Yearly",
                                            value: "yearly",
                                        },
                                    ]}
                                />
                            </div>
                            <div className="badge flag">
                                <div className="photo">
                                    <img
                                        src={
                                            this.state.graphData.country.first
                                                .flag
                                        }
                                        className="img-block"
                                        alt="flag"
                                    />
                                    <div className="icon">
                                        <img
                                            src={WinnerIcon}
                                            className="img-block"
                                            alt="winner"
                                        />
                                    </div>
                                </div>
                                <span className="title">
                                    {this.state.graphData.country.first.value}{" "}
                                    users
                                </span>
                            </div>
                            <div className="country-container">{countries}</div>
                        </div>
                    </div>
                    <div className="adb-container">
                        <AdvanceDatabox
                            title="Number of challenges"
                            value={this.state.challenges}
                            updateData={this.updateUserCountryGraph}
                            dropdown={{
                                title: "Period",
                                items: [
                                    {
                                        text: 'All',
                                        value: 'all'
                                    },
                                    {
                                        text: 'Daily',
                                        value: 'daily',
                                        active: true,
                                    },
                                    {
                                        text: "Weekly",
                                        value: "weekly",
                                    },
                                    {
                                        text: "Monthly",
                                        value: "monthly",
                                    },
                                    {
                                        text: "Yearly",
                                        value: "yearly",
                                    },
                                ],
                            }}
                        />
                        <AdvanceDatabox
                            title="Number of livestreams"
                            value={this.state.livestreams}
                            updateData={this.updateUserCountryGraph}
                            dropdown={{
                                title: "Period",
                                items: [
                                    {
                                        text: 'All',
                                        value: 'all'
                                    },
                                    {
                                        text: 'Daily',
                                        value: 'daily',
                                        active: true
                                    },
                                    {
                                        text: "Weekly",
                                        value: "weekly",
                                    },
                                    {
                                        text: "Monthly",
                                        value: "monthly",
                                    },
                                    {
                                        text: "Yearly",
                                        value: "yearly",
                                    },
                                ],
                            }}
                        />
                    </div>
                    <div className="top-data">
                        <div className="users">
                            <Title text="Top Users" more="/" />
                            <Carousel
                                breakpoints={{
                                    900: {
                                        slidesPerPage: 1,
                                        arrows: false,
                                        offset: 0,
                                    },
                                }}
                                slidesPerPage={3}
                                offset={10}
                                arrows
                            >
                                {topUsers}
                            </Carousel>
                        </div>
                        <div className="streams">
                            <Title text="Top Challenges" more="/" />
                            <Carousel
                                breakpoints={{
                                    900: {
                                        slidesPerPage: 1,
                                        arrows: false,
                                        offset: 0,
                                    },
                                }}
                                slidesPerPage={3}
                                offset={10}
                                arrows
                            >
                                {topChallenges}
                            </Carousel>
                        </div>
                    </div>
                    <div className="top-data">
                        <div className="strem-videos">
                            <Title text="Top Video on Stream" more="/" />
                            <Carousel
                                breakpoints={{
                                    900: {
                                        slidesPerPage: 1,
                                        arrows: false,
                                        offset: 0,
                                    },
                                }}
                                slidesPerPage={3}
                                offset={10}
                                arrows
                            >
                                {topStreamVideos}
                            </Carousel>
                        </div>
                        <div className="challenge-videos">
                            <Title text="Top video on Challenge" more="/" />
                            <Carousel
                                breakpoints={{
                                    900: {
                                        slidesPerPage: 1,
                                        arrows: false,
                                        offset: 0,
                                    },
                                }}
                                slidesPerPage={3}
                                offset={10}
                                arrows
                            >
                                {topChallengeVideos}
                            </Carousel>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default withRouter(Dashboard);
