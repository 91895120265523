import React from "react";
import "./ScrollBox.scss";

const ScrollBox = (props) => {
    return (
        <div
            style={{ height: props.height }}
            className={"scrollbox " + (props.className ? props.className : "")}
        >
            {props.children}
        </div>
    );
};

export default ScrollBox;
