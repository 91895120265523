import React from "react";
import "./UsersModal.scss";
import ModalLayout from "../../../components/ModalLayout/ModalLayout";
import Photo1 from "../../../assets/images/users/user-1.jpg";
import SearchBox from "../../../components/SearchBox/SearchBox";
import User from "../../../components/UserList/User/User";
import ScrollBox from "../../../components/ScrollBox/ScrollBox";
import Button from "../../../components/UI/Button/Button";

class UserModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [
                {
                    id: 1,
                    photo: Photo1,
                    name: "John Smith",
                    amount: 800,
                },
                {
                    id: 2,
                    photo: Photo1,
                    name: "John Smith",
                    amount: 800,
                },
                {
                    id: 3,
                    photo: Photo1,
                    name: "John Smith",
                    amount: 800,
                },
                {
                    id: 4,
                    photo: Photo1,
                    name: "John Smith",
                    amount: 800,
                },
                {
                    id: 5,
                    photo: Photo1,
                    name: "John Smith",
                    amount: 800,
                },
                {
                    id: 6,
                    photo: Photo1,
                    name: "John Smith",
                    amount: 800,
                },
                {
                    id: 7,
                    photo: Photo1,
                    name: "John Smith",
                    amount: 800,
                },
                {
                    id: 8,
                    photo: Photo1,
                    name: "John Smith",
                    amount: 800,
                },
                {
                    id: 9,
                    photo: Photo1,
                    name: "John Smith",
                    amount: 800,
                },
                {
                    id: 10,
                    photo: Photo1,
                    name: "John Smith",
                    amount: 800,
                },
                {
                    id: 11,
                    photo: Photo1,
                    name: "John Smith",
                    amount: 800,
                },
            ],
        };
    }

    render() {
        const users = this.state.users.map((user) => (
            <User key={user.id} hideLabel={this.props.hideLabel} singleLabel user={user} />
        ));

        return (
            <ModalLayout closeHandler={this.props.closeHandler}>
                <div className="user-modal">
                    <div className="top">
                        <h4 className="title aqua">{this.props.title}</h4>
                        <SearchBox />
                    </div>
                    <div className="bottom">
                        <ScrollBox
                            height="calc(100vh - 40rem)"
                            className="wide"
                        >
                            {users}
                        </ScrollBox>
                    </div>
                    <div className="foot">
                        <Button className="btn-green btn-sm low-red">
                            Done
                        </Button>
                        <Button
                            onClick={this.props.closeHandler}
                            className="btn-red btn-sm low-red"
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
            </ModalLayout>
        );
    }
}

export default UserModal;
