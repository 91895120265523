import React from "react";
import './Thumbnail.scss';
import PlayIcon from "../../assets/images/icons/videoIcon.png";

const Thumbnail = (props) => {
    return (
        <div className="thumbnail">
            <img
                src={props.photo}
                alt="thumbnail"
                className="img-block"
            />
            <img src={PlayIcon} className="play" alt="play" />
        </div>
    );
};

export default Thumbnail;
