import React from "react";
import "./Auth.scss";
import LogoShape from "../../assets/images/Logo-Shape.png";
import TopShape from "../../assets/images/Top-Shape.png";
import BottomShape from "../../assets/images/Bottom-Shape.png";
import { Switch, Route } from "react-router-dom";
import Login from "./Login";
import ForgetPassword from "./ForgetPassword";

class Auth extends React.Component {
    render() {
        return (
            <div className="auth-container">
                <div className="topshape tab-port-hide">
                    <img src={TopShape} alt="shape" />
                </div>
                <div className="left">
                    <div className="content">
                        <div className="logobox">
                            <img src={LogoShape} alt="Logo" />
                            <h2 className="light-heading">Streamantics</h2>
                        </div>
                        <div className="textbox">
                            <h1>
                                Find new
                                <br />
                                Streamer nearby
                            </h1>
                            <p>
                                Lorem ipsum, dolor sit amet consectetur
                                adipisicing elit. Molestias ab voluptatibus
                                minus nostrum sed repudiandae est.
                            </p>
                        </div>
                    </div>
                </div>
                <Switch>
                    <Route path="/auth/login">
                        <Login className="right" />
                    </Route>
                    <Route path="/auth/forget-password">
                        <ForgetPassword className="right" />
                    </Route>
                </Switch>
                <div className="bottomshape tab-port-hide">
                    <img src={BottomShape} alt="shape" />
                </div>
            </div>
        );
    }
}

export default Auth;
