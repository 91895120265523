import React from "react";
import './NotificationCreate.scss';
import Layout from "../../../components/Layout/Layout";
import Button from "../../../components/UI/Button/Button";
import Input from "../../../components/UI/Input/Input";
import UsersModal from "../../../containers/User/UsersModal/UsersModal";

class NotificationCreate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notification: {
                users: "",
                time: "",
                date: "",
                text: "",
            },
            UsersModalVisible: false,
        };

        this.UserSelectionHandler = this.UserSelectionHandler.bind(this);
        this.toggleUsersModalHandler = this.toggleUsersModalHandler.bind(this);
    }

    UserSelectionHandler(selection) {
        if (selection === 'users') {
            this.toggleUsersModalHandler();
        }
    }

    toggleUsersModalHandler() {
        this.setState((state) => ({
            UsersModalVisible: !state.UsersModalVisible,
        }));
    }

    render() {
        let users;

        if (this.state.UsersModalVisible) {
            users = (
                <UsersModal
                    title="Users"
                    hideLabel
                    closeHandler={this.toggleUsersModalHandler}
                />
            );
        }

        return (
            <Layout>
                <div id="NotificationCreate">
                    <h3 className="title aqua">Send Push Notification</h3>
                    <form>
                        <Input
                            className="block dark large"
                            type="dropdown"
                            placeholder="Select User/Group"
                            onChange={this.UserSelectionHandler}
                            items = {[
                                {text: 'All', value: 'all'},
                                {text: 'Streamers', value: 'streamers'},
                                {text: 'Challengers', value: 'challengers'},
                                {text: 'Users', value: 'users'},
                            ]}
                        />
                        <Input
                            className="block dark large"
                            type="datetime"
                            placeholder="Select time to send Notification"
                        />
                        <Input
                            className="block dark large"
                            type="textarea"
                            placeholder="Add your text here"
                        />
                    </form>
                    <Button className="btn-orange btn-lg send">SEND</Button>
                </div>
                {users}
            </Layout>
        );
    }
}

export default NotificationCreate;
