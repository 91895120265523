import React from 'react';
import "./Topbar.scss";
import SearchBox from '../SearchBox/SearchBox';
import MessageBox from './MessageBox/MessageBox';
import NotificationBox from './NotificationBox/NotificationBox';
import UserBox from './UserBox/UserBox';

const Topbar = () => {
    return (
        <div id="topbar">
            <div className="left">
                <SearchBox />
            </div>
            <div className="right">
                <MessageBox />
                <NotificationBox />
                <UserBox />
            </div>
        </div>
     );
}

export default Topbar;
