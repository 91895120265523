import React from "react";
import "./NotificationBox.scss";
import NotificationIcon from "../../../assets/images/icons/NotificationIcon.png";
import {withRouter} from "react-router-dom";

class NotificationBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notifications: null,
            isUnread: false,
        };

        this.ClickHandler = this.ClickHandler.bind(this);
    }

    ClickHandler() {
        this.setState({
            isUnread: false,
        });
        this.props.history.push('/notifications');
    }

    render() {
        let unreadIndicator;

        if (this.state.isUnread) {
            unreadIndicator = <span className="unreadIndicator"></span>;
        }

        return (
            <div className="notificationbox" onClick={this.ClickHandler}>
                <img src={NotificationIcon} alt="Notifications" className="icon img-fluid" />
                {unreadIndicator}
            </div>
        );
    }
}

export default withRouter(NotificationBox);
