import React from "react";
import "./Notification.scss";
import Button from "../UI/Button/Button";
import DeleteRed from "../../assets/images/icons/DeleteRed.png";

const Notification = ({notification}) => {
    let actions;

    if (notification.pending) {
        actions = (
            <div className="actions">
                <div className="delete">
                    <img src={DeleteRed} alt="delete" />
                </div>
                <Button className="btn-dark btn-sm">Edit</Button>
                <Button className="btn-orange btn-sm">SEND</Button>
            </div>
        );
    }

    return (
        <div>
            <div className="notification">
                <div className="left">
                    <p className="text">{notification.text}</p>
                    <span className="channel">{notification.channel}</span>
                    <span className="date">{notification.date}</span>
                </div>
                <div className="right">{actions}</div>
            </div>
        </div>
    );
};

export default Notification;
