import React from "react";
import "./UserIndex.scss";
import UserRow from "../../../components/UserRow/UserRow";
import Layout from "../../../components/Layout/Layout";
import Photo1 from "../../../assets/images/users/user-1.jpg";
import Photo2 from "../../../assets/images/users/user-2.jpg";
import USAFlag from "../../../assets/images/icons/USAFlag.png";
import CanadaFlag from "../../../assets/images/icons/CanadaFlag.png";

class UserIndex extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [
                {
                    id: 1,
                    name: "Linus Tech",
                    email: "Linushtech@gmail.com",
                    registerDate: "05-05-2019",
                    follower: "12K",
                    following: "120",
                    amountSpent: "24K",
                    isOnline: true,
                    isEmailVerified: true,
                    isDropdownOpen: false,
                    photo: Photo1,
                    flag: USAFlag,
                },
                {
                    id: 2,
                    name: "Linus Tech",
                    email: "Linushtech@gmail.com",
                    registerDate: "05-05-2019",
                    follower: "12K",
                    following: "120",
                    amountSpent: "24K",
                    isOnline: true,
                    isEmailVerified: false,
                    isDropdownOpen: false,
                    photo: Photo2,
                    flag: CanadaFlag,
                },
                {
                    id: 3,
                    name: "Linus Tech",
                    email: "Linushtech@gmail.com",
                    registerDate: "05-05-2019",
                    follower: "12K",
                    following: "120",
                    amountSpent: "24K",
                    isOnline: true,
                    isEmailVerified: true,
                    isDropdownOpen: false,
                    photo: Photo1,
                    flag: USAFlag,
                },
                {
                    id: 4,
                    name: "Linus Tech",
                    email: "Linushtech@gmail.com",
                    registerDate: "05-05-2019",
                    follower: "12K",
                    following: "120",
                    amountSpent: "24K",
                    isOnline: true,
                    isEmailVerified: false,
                    isDropdownOpen: false,
                    photo: Photo2,
                    flag: CanadaFlag,
                },
                {
                    id: 5,
                    name: "Linus Tech",
                    email: "Linushtech@gmail.com",
                    registerDate: "05-05-2019",
                    follower: "12K",
                    following: "120",
                    amountSpent: "24K",
                    isOnline: true,
                    isEmailVerified: true,
                    isDropdownOpen: false,
                    photo: Photo1,
                    flag: USAFlag,
                },
                {
                    id: 6,
                    name: "Linus Tech",
                    email: "Linushtech@gmail.com",
                    registerDate: "05-05-2019",
                    follower: "12K",
                    following: "120",
                    amountSpent: "24K",
                    isOnline: true,
                    isEmailVerified: false,
                    isDropdownOpen: false,
                    photo: Photo2,
                    flag: CanadaFlag,
                },
                {
                    id: 7,
                    name: "Linus Tech",
                    email: "Linushtech@gmail.com",
                    registerDate: "05-05-2019",
                    follower: "12K",
                    following: "120",
                    amountSpent: "24K",
                    isOnline: true,
                    isEmailVerified: true,
                    isDropdownOpen: false,
                    photo: Photo1,
                    flag: USAFlag,
                },
                {
                    id: 8,
                    name: "Linus Tech",
                    email: "Linushtech@gmail.com",
                    registerDate: "05-05-2019",
                    follower: "12K",
                    following: "120",
                    amountSpent: "24K",
                    isOnline: true,
                    isEmailVerified: false,
                    isDropdownOpen: false,
                    photo: Photo2,
                    flag: CanadaFlag,
                },
                {
                    id: 9,
                    name: "Linus Tech",
                    email: "Linushtech@gmail.com",
                    registerDate: "05-05-2019",
                    follower: "12K",
                    following: "120",
                    amountSpent: "24K",
                    isOnline: true,
                    isEmailVerified: true,
                    isDropdownOpen: false,
                    photo: Photo1,
                    flag: USAFlag,
                },
                {
                    id: 10,
                    name: "Linus Tech",
                    email: "Linushtech@gmail.com",
                    registerDate: "05-05-2019",
                    follower: "12K",
                    following: "120",
                    amountSpent: "24K",
                    isOnline: true,
                    isEmailVerified: false,
                    isDropdownOpen: false,
                    photo: Photo2,
                    flag: CanadaFlag,
                },
                {
                    id: 11,
                    name: "Linus Tech",
                    email: "Linushtech@gmail.com",
                    registerDate: "05-05-2019",
                    follower: "12K",
                    following: "120",
                    amountSpent: "24K",
                    isOnline: true,
                    isEmailVerified: true,
                    isDropdownOpen: false,
                    photo: Photo1,
                    flag: USAFlag,
                },
                {
                    id: 12,
                    name: "Linus Tech",
                    email: "Linushtech@gmail.com",
                    registerDate: "05-05-2019",
                    follower: "12K",
                    following: "120",
                    amountSpent: "24K",
                    isOnline: true,
                    isEmailVerified: false,
                    isDropdownOpen: false,
                    photo: Photo2,
                    flag: CanadaFlag,
                },
                {
                    id: 13,
                    name: "Jahid",
                    email: "Linushtech@gmail.com",
                    registerDate: "05-05-2019",
                    follower: "12K",
                    following: "120",
                    amountSpent: "24K",
                    isOnline: true,
                    isEmailVerified: false,
                    isDropdownOpen: false,
                    photo: Photo2,
                    flag: CanadaFlag,
                },
            ],
        };
    }
    render() {
        const users = this.state.users.map((user) => (
            <UserRow
                key={user.id}
                id={user.id}
                photo={user.photo}
                name={user.name}
                email={user.email}
                registerDate={user.registerDate}
                follower={user.follower}
                following={user.following}
                amountSpent={user.amountSpent}
                isOnline={user.isOnline}
                isEmailVerified={user.isEmailVerified}
                isDropdownOpen={user.isDropdownOpen}
                flag={user.flag}
            />
        ));

        return (
            <Layout overlay>
                <div id="users">{users}</div>
            </Layout>
        );
    }
}

export default UserIndex;
