import React from "react";
import "./Dropdown.scss";
import CloseIcon from "../../assets/images/icons/CloseIcon.png";

const Dropdown = (props) => {
    let items = props.items.map((item) => {
        let icon;
        if (item.icon) {
            icon = (
                <div className="iconbox">
                    <img className="img-fluid" src={item.icon} alt="icon" />
                </div>
            );
        }

        return (
            <li
                key={item.text}
                className={icon ? "with-icon" : ""}
                onClick={item.onClick}
            >
                {icon}
                <p className="text">{item.text}</p>
            </li>
        );
    });

    return (
        <div
            className={
                "dropdownbox " + (props.className ? props.className : "")
            }
        >
            <div onClick={props.CloseHandler} className="closeicon">
                <img src={CloseIcon} alt="close" />
            </div>
            <ul>{items}</ul>
        </div>
    );
};

export default Dropdown;
