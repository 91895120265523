import React from "react";
import './DropdownTrigger.scss';

const DropdownTrigger = (props) => {
    return (
        <div className="dropdown-trigger" onClick={props.onClick}>
            <span></span>
        </div>
    );
};

export default DropdownTrigger;
