import React from "react";
import "./Participant.scss";
import PlayIcon from "../../assets/images/icons/PlayIcon.png";

const Participant = (props) => {
    return (
        <div className="participant">
            <img
                src={props.participant.photo}
                alt="participant"
                className="img-block photo"
            />
            <img src={PlayIcon} className="icon" alt="play icon" />
        </div>
    );
};

export default Participant;
