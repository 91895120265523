import React from "react";
import "./Mail.scss";
import Layout from "../../components/Layout/Layout";
import Button from "../../components/UI/Button/Button";
import Photo1 from "../../assets/images/users/user-1.jpg";
import MailBar from "../../components/MailBar/MailBar";
import MailBox from "../../components/MailBox/MailBox";
import ComposeBox from "../../components/ComposeBox/ComposeBox";
import Compose from "../../assets/images/icons/Compose.png";
import Inbox from "../../assets/images/icons/Inbox.png";
import Sent from "../../assets/images/icons/Sent.png";
import Draft from "../../assets/images/icons/Draft.png";
import Delete from "../../assets/images/icons/delete-white-icon.png";
import SearchIcon from "../../assets/images/icons/SearchIcon2.png";
import ScrollBox from "../../components/ScrollBox/ScrollBox";

class Mail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            unread: 2,
            mails: [
                {
                    id: 1,
                    sender: "John Smith",
                    photo: Photo1,
                    category: "meeting",
                    isNew: true,
                    excerpt:
                        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat, dolorum? Consectetur, odio aperiam corrupti sunt ratione maiores?",
                    date: "Sun, Apr 17,2011",
                    isDropdownOpen: true,
                },
                {
                    id: 2,
                    sender: "John Smith",
                    photo: Photo1,
                    category: "meeting",
                    isNew: false,
                    excerpt:
                        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat, dolorum? Consectetur, odio aperiam corrupti sunt ratione maiores?",
                    date: "Sun, Apr 17,2011",
                    isDropdownOpen: true,
                },
                {
                    id: 3,
                    sender: "John Smith",
                    photo: Photo1,
                    category: "meeting",
                    isNew: true,
                    excerpt:
                        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat, dolorum? Consectetur, odio aperiam corrupti sunt ratione maiores?",
                    date: "Sun, Apr 17,2011",
                    isDropdownOpen: true,
                },
                {
                    id: 4,
                    sender: "John Smith",
                    photo: Photo1,
                    category: "meeting",
                    isNew: false,
                    excerpt:
                        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat, dolorum? Consectetur, odio aperiam corrupti sunt ratione maiores?",
                    date: "Sun, Apr 17,2011",
                    isDropdownOpen: true,
                },
                {
                    id: 5,
                    sender: "John Smith",
                    photo: Photo1,
                    category: "meeting",
                    isNew: true,
                    excerpt:
                        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat, dolorum? Consectetur, odio aperiam corrupti sunt ratione maiores?",
                    date: "Sun, Apr 17,2011",
                    isDropdownOpen: true,
                },
                {
                    id: 6,
                    sender: "John Smith",
                    photo: Photo1,
                    category: "meeting",
                    isNew: false,
                    excerpt:
                        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat, dolorum? Consectetur, odio aperiam corrupti sunt ratione maiores?",
                    date: "Sun, Apr 17,2011",
                    isDropdownOpen: true,
                },
                {
                    id: 7,
                    sender: "John Smith",
                    photo: Photo1,
                    category: "meeting",
                    isNew: true,
                    excerpt:
                        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat, dolorum? Consectetur, odio aperiam corrupti sunt ratione maiores?",
                    date: "Sun, Apr 17,2011",
                    isDropdownOpen: true,
                },
                {
                    id: 8,
                    sender: "John Smith",
                    photo: Photo1,
                    category: "meeting",
                    isNew: false,
                    excerpt:
                        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat, dolorum? Consectetur, odio aperiam corrupti sunt ratione maiores?",
                    date: "Sun, Apr 17,2011",
                    isDropdownOpen: true,
                },
            ],
            isComposing: true,
            tab: "inbox",
            selectedMail: null,
        };

        this.activateComposeHandler = this.activateComposeHandler.bind(this);
    }

    activateComposeHandler() {
        this.setState({
            isComposing: true,
        });
    }

    showMailHandler(id) {
        this.setState({
            isComposing: false,
            selectedMail: id,
        });
    }

    render() {
        let mailbox, action;

        if (this.state.isComposing) {
            mailbox = <ComposeBox />;
            action = (
                <div className="action">
                    <Button className="btn-orange py-less">SEND</Button>
                </div>
            );
        } else {
            mailbox = (
                <div className="mailbox-container">
                    <p className="head">
                        5 Previous mails in this conversation
                    </p>
                    <MailBox mailId={this.state.selectedMail} />
                </div>
            );
            action = (
                <div className="action">
                    <Button className="btn-orange py-less">REPLY</Button>
                    <Button className="btn-aqua py-less">Forward</Button>
                </div>
            );
        }

        const mails = this.state.mails.map((mail) => (
            <MailBar
                onClick={this.showMailHandler.bind(this, mail.id)}
                key={mail.id}
                mail={mail}
                unread={mail.isNew}
                active={this.state.selectedMail === mail.id}
            />
        ));

        return (
            <Layout>
                <div id="mail">
                    <div className="btn-group">
                        <Button
                            onClick={this.activateComposeHandler}
                            icon={Compose}
                            className="btn-orange with-icon"
                        >
                            Compose
                        </Button>
                        <Button
                            icon={Inbox}
                            badge={this.state.unread}
                            className="btn-aqua-gra px-less with-icon with-badge"
                        >
                            Inbox
                        </Button>
                        <Button
                            icon={Sent}
                            className="with-icon px-less btn-grey"
                        >
                            Sent
                        </Button>
                        <Button
                            icon={Draft}
                            className="with-icon px-less btn-grey"
                        >
                            Draft
                        </Button>
                        <Button
                            icon={Delete}
                            className="with-icon px-less btn-grey"
                        >
                            Delete
                        </Button>
                    </div>
                    <div className="mail-section">
                        <div className="left">
                            <div className="search-mail">
                                <img src={SearchIcon} alt="search" />
                                <input type="text" placeholder="Search Mail" />
                            </div>
                            <ScrollBox height="calc(100vh - 115px - 24rem)">
                                <div className="mail-box">{mails}</div>
                            </ScrollBox>
                        </div>
                        <div className="right">
                            {mailbox}
                            {action}
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default Mail;
