import React from "react";
import { Link } from "react-router-dom";
import HorizontalLogo from "../../assets/images/Logo-Horizontal.png";
import UserDark from "../../assets/images/icons/user-dark.png";
import Button from "../../components/UI/Button/Button";
import Input from "../../components/UI/Input/Input";

const ForgetPassword = (props) => (
    <div className={props.className}>
        <div className="heading-box">
            <h2 className="light-heading">RESET PASSWORD</h2>
            <img src={HorizontalLogo} alt="Logo" />
        </div>
        <div className="inputbox">
            <Input
                type="email"
                placeholder="Email"
                className="block white"
                icon={UserDark}
            />
        </div>
        <div className="actionbox">
            <Button className="btn-block btn-lg btn-orange">RESET</Button>
            <div className="bottomarea single">
                <Link to="/auth/login">Return to Login</Link>
            </div>
        </div>
    </div>
);

export default ForgetPassword;
