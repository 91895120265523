import React from "react";
import "./ChallengeIndex.scss";
import Layout from "../../../components/Layout/Layout";
import Arrow from "../../../assets/images/icons/DownArrowAqua.png";
import Dropdown from "../../../components/Dropdown/Dropdown";
import Challenge from "../../../components/Challenge/Challenge";
import UserPhoto from "../../../assets/images/users/user-1.jpg";
import {withRouter} from 'react-router-dom';
import ChallengeDetails from "../ChallengeDetails/ChallengeDetails";

class ChallengeIndex extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            challenges: [
                {
                    id: 1,
                    title: "Building the Best Streamer Computer",
                    views: "20.5k",
                    isPublic: true,
                    excerpt: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Impedit maiores, voluptatum accusantium dolorum similique aspernatur, reprehenderit aliquid veniam at nam distinctio!",
                    winner: {
                        photo: UserPhoto
                    },
                    user: {
                        name: "Linus Tech",
                        title: "Creator",
                        photo: UserPhoto
                    },
                    participants: [
                        {
                            id: 1,
                            photo: UserPhoto
                        },
                        {
                            id: 2,
                            photo: UserPhoto
                        },
                        {
                            id: 3,
                            photo: UserPhoto
                        },
                    ],
                },
                {
                    id: 2,
                    title: "Building the Best Streamer Computer",
                    views: "20.5k",
                    isPublic: true,
                    excerpt: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Impedit maiores, voluptatum accusantium dolorum similique aspernatur, reprehenderit aliquid veniam at nam distinctio!",
                    winner: null,
                    user: {
                        name: "Linus Tech",
                        title: "Creator",
                        photo: UserPhoto
                    },
                    participants: [
                        {
                            id: 1,
                            photo: UserPhoto
                        },
                        {
                            id: 2,
                            photo: UserPhoto
                        },
                        {
                            id: 3,
                            photo: UserPhoto
                        },
                    ]
                },
                {
                    id: 3,
                    title: "Building the Best Streamer Computer",
                    views: "20.5k",
                    isPublic: false,
                    excerpt: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Impedit maiores, voluptatum accusantium dolorum similique aspernatur, reprehenderit aliquid veniam at nam distinctio!",
                    winner: null,
                    user: {
                        name: "Linus Tech",
                        title: "Creator",
                        photo: UserPhoto
                    },
                    participants: [
                        {
                            id: 1,
                            photo: UserPhoto
                        },
                        {
                            id: 2,
                            photo: UserPhoto
                        },
                        {
                            id: 3,
                            photo: UserPhoto
                        },
                    ]
                },
                {
                    id: 4,
                    title: "Building the Best Streamer Computer",
                    views: "20.5k",
                    isPublic: false,
                    excerpt: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Impedit maiores, voluptatum accusantium dolorum similique aspernatur, reprehenderit aliquid veniam at nam distinctio!",
                    winner: null,
                    user: {
                        name: "Linus Tech",
                        title: "Creator",
                        photo: UserPhoto
                    },
                    participants: [
                        {
                            id: 1,
                            photo: UserPhoto
                        },
                        {
                            id: 2,
                            photo: UserPhoto
                        },
                        {
                            id: 3,
                            photo: UserPhoto
                        },
                    ]
                },
                {
                    id: 5,
                    title: "Building the Best Streamer Computer",
                    views: "20.5k",
                    isPublic: false,
                    excerpt: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Impedit maiores, voluptatum accusantium dolorum similique aspernatur, reprehenderit aliquid veniam at nam distinctio!",
                    winner: null,
                    user: {
                        name: "Linus Tech",
                        title: "Creator",
                        photo: UserPhoto
                    },
                    participants: [
                        {
                            id: 1,
                            photo: UserPhoto
                        },
                        {
                            id: 2,
                            photo: UserPhoto
                        },
                        {
                            id: 3,
                            photo: UserPhoto
                        },
                    ]
                },
                {
                    id: 6,
                    title: "Customize the Best Streamer Computer",
                    views: "20.5k",
                    isPublic: false,
                    excerpt: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Impedit maiores, voluptatum accusantium dolorum similique aspernatur, reprehenderit aliquid veniam at nam distinctio!",
                    winner: null,
                    user: {
                        name: "Linus Tech",
                        title: "Creator",
                        photo: UserPhoto
                    },
                    participants: [
                        {
                            id: 1,
                            photo: UserPhoto
                        },
                        {
                            id: 2,
                            photo: UserPhoto
                        },
                        {
                            id: 3,
                            photo: UserPhoto
                        },
                    ]
                },
            ],
            dropdown: {
                isVisible: false,
                items: [
                    {
                        text: "January",
                    },
                    {
                        text: "Februaray",
                    },
                    {
                        text: "March",
                    },
                    {
                        text: "April",
                    },
                    {
                        text: "May",
                    },
                    {
                        text: "June",
                    },
                ],
            },
            DetailsModalVisible: false,
            selectedChallenge: null
        };

        this.ToggleDropdownHandler = this.ToggleDropdownHandler.bind(this);
        this.hideDetailsModal = this.hideDetailsModal.bind(this);
    }

    ToggleDropdownHandler() {
        this.setState((state) => ({
            dropdown: {
                ...state.dropdown,
                isVisible: !state.dropdown.isVisible,
            },
        }));
    }

    hideDetailsModal() {
        this.setState({
            DetailsModalVisible: false
        })
    }

    componentDidMount(){
        if (this.props.match.params.challengeId) {
            this.setState({
                DetailsModalVisible: true,
                selectedChallenge: this.state.challenges[0]
            });
        }
    }

    render() {
        let dropdown, detailsModal;

        if (this.state.DetailsModalVisible) {
            detailsModal = (
                <ChallengeDetails
                    closeHandler={this.hideDetailsModal}
                    challenge={this.state.selectedChallenge}
                />
            );
        }

        if (this.state.dropdown.isVisible) {
            dropdown = (
                <Dropdown
                    CloseHandler={this.ToggleDropdownHandler}
                    className="light"
                    items={this.state.dropdown.items}
                />
            );
        }

        return (
            <Layout>
                <div id="challenge-index">
                    <div className="title">
                        <h2>Challenge List</h2>
                        <span className="line tab-port-hide"></span>
                        <div className="filter">
                            Last Update:{" "}
                            <div
                                onClick={this.ToggleDropdownHandler}
                                className="dropdown-trigger"
                            >
                                June
                                <img src={Arrow} alt="arrow" />
                            </div>
                            {dropdown}
                        </div>
                    </div>
                    <div className="challenge-container">
                        {this.state.challenges.map((challenge) => (
                            <Challenge key={challenge.id} challenge={challenge} />
                        ))}
                    </div>
                </div>
                {detailsModal}
            </Layout>
        );
    }
}

export default withRouter(ChallengeIndex);
