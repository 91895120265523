import React from "react";
import "./ChallengeTitle.scss";
import EyeIcon from "../../assets/images/icons/EyeIconOrange.png";

const ChallengeTitle = (props) => {
    return (
        <div className="challenge-title" onClick={props.onClick}>
            <div className="top">
                <div className="user">
                    <div className="photo">
                        <img src={props.challenge.user.photo} className="img-block" alt="user" />
                    </div>
                    <div className="info">
                        <h4 className="title">{props.challenge.user.title}</h4>
                        <h4 className="name">{props.challenge.user.name}</h4>
                    </div>
                </div>
                <h3 className="title">{props.challenge.title}</h3>
                <div className="badges">
                    <div className="view">
                        <img src={EyeIcon} alt="icon" />
                        {props.challenge.views}
                    </div>
                    <div
                        className={
                            "state " +
                            (props.challenge.isPublic ? "green" : "red")
                        }
                    >
                        {props.challenge.isPublic ? "Public" : "Privete"}
                    </div>
                </div>
            </div>
            <div className="bottom">
                <p>{props.challenge.excerpt}</p>
            </div>
        </div>
    );
};

export default ChallengeTitle;
