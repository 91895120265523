import React from "react";
import DropdownTrigger from "../DropdownTrigger/DropdownTrigger";
import WinnerIcon from "../../assets/images/icons/WinnerIcon.png";
import "./RankBox.scss";

class RankBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isDropdownVisible: false,
        };
    }
    render() {
        let badge, images;

        if (Array.isArray(this.props.photo) && this.props.photo.length > 1) {
            images = (
                <div className={this.props.photo.length === 2 ? "two" : "more"}>
                    {this.props.photo.map((photo) => (
                        <img key={photo.photo} src={photo.photo} alt="user" />
                    ))}
                </div>
            );
        } else {
            images = (
                <img
                    src={
                        Array.isArray(this.props.photo)
                            ? this.props.photo[0].photo
                            : this.props.photo
                    }
                    alt="user"
                />
            );
        }

        if (this.props.rank === 1) {
            badge = <img src={WinnerIcon} alt="winner" />;
        } else {
            badge = <span>{this.props.rank}</span>;
        }

        return (
            <div onClick={this.props.onClick} className="rankbox">
                <div className="top">
                    <div className="badge">{badge}</div>
                    <div className="dropdown-container">
                        <DropdownTrigger />
                    </div>
                </div>
                <div className="middle">
                    <div className="image-box">{images}</div>
                    <h4 className="title">{this.props.title}</h4>
                    <span className="subtitle">
                        {/* <img src={this.props.subtitleIcon} alt="icon" />{" "} */}
                        {this.props.subtitle}
                    </span>
                </div>
                <div className="bottom">
                    <div className="box">
                        <h6 className="title">{this.props.data1.title}</h6>
                        <span className="value">{this.props.data1.value}</span>
                    </div>
                    <div className="box">
                        <h6 className="title">{this.props.data2.title}</h6>
                        <span className="value">{this.props.data2.value}</span>
                    </div>
                </div>
            </div>
        );
    }
}

export default RankBox;
