import React from "react";
import "./Challenge.scss";
import ChallengeTitle from "../ChallengeTitle/ChallengeTitle";
import DropdownTrigger from "../DropdownTrigger/DropdownTrigger";
import Dropdown from "../Dropdown/Dropdown";
import Participant from "../Participant/Participant";
import Pin from "../../assets/images/icons/PinLight.png";
import Power from "../../assets/images/icons/PowerLight.png";
import Cross from "../../assets/images/icons/CrossLight.png";
import WinnerIcon from "../../assets/images/icons/WinnerIcon.png";
import ChallengeDetails from "../../containers/Challenge/ChallengeDetails/ChallengeDetails";
import Live from "../../assets/images/icons/Live.png";

class Challenge extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            DetailsModalVisible: false,
            dropdown: {
                isVisible: false,
                items: [
                    {
                        text: "Cancel challenge",
                        icon: Power,
                    },
                    {
                        text: "Edit Challenge",
                        icon: Pin,
                    },
                    {
                        text: "Close Challenge",
                        icon: Cross,
                    },
                ],
            },
        };

        this.toggleDropdownHandler = this.toggleDropdownHandler.bind(this);
        this.toggleDetailsModalHandler = this.toggleDetailsModalHandler.bind(
            this
        );
    }

    toggleDetailsModalHandler(e) {
        this.setState((props) => ({
            DetailsModalVisible: !props.DetailsModalVisible,
        }));
    }

    toggleDropdownHandler() {
        this.setState((state) => ({
            dropdown: {
                ...state.dropdown,
                isVisible: !state.dropdown.isVisible,
            },
        }));
    }

    render() {
        let dropdown, participants, winner, detailsModal;

        if (this.state.dropdown.isVisible) {
            dropdown = (
                <Dropdown
                    CloseHandler={this.toggleDropdownHandler}
                    className="light"
                    items={this.state.dropdown.items}
                />
            );
        }

        if (this.state.DetailsModalVisible) {
            detailsModal = (
                <ChallengeDetails
                    closeHandler={this.toggleDetailsModalHandler}
                    challenge={this.props.challenge}
                />
            );
        }

        participants = this.props.challenge.participants.map((participant) => (
            <Participant key={participant.id} participant={participant} />
        ));

        if (this.props.challenge.winner) {
            winner = (
                <div className="winner">
                    <h5 className="title">Winner</h5>
                    <div className="winner-container">
                        <div className="photo">
                            <img
                                src={this.props.challenge.winner.photo}
                                className="img-block"
                                alt="winner"
                            />
                        </div>
                        <div className="icon">
                            <img src={WinnerIcon} alt="icon" />
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div>
                <div className="challenge">
                    <div className="dropdown">
                        <DropdownTrigger onClick={this.toggleDropdownHandler} />
                        {dropdown}
                    </div>
                    <ChallengeTitle
                        onClick={this.toggleDetailsModalHandler}
                        challenge={this.props.challenge}
                    />
                    <div className="bottom">
                        <div className="participants">
                            <h5 className="title">Participants</h5>
                            <div className="participant-container">
                                {participants}
                            </div>
                        </div>
                        <div className="donation-container">
                            <span>Donation</span>
                            <div className="amount">$250</div>
                            <span>USD</span>
                        </div>
                        {this.props.challenge.winner ? (
                            winner
                        ) : (
                            <img src={Live} className="live" alt="live" />
                        )}
                    </div>
                </div>
                {detailsModal}
            </div>
        );
    }
}

export default Challenge;
