import React from "react";
import "./Setting.scss";
import Layout from "../../components/Layout/Layout";
import Photo1 from "../../assets/images/users/user-1.jpg";
import Button from "../../components/UI/Button/Button";
import Input from "../../components/UI/Input/Input";

class Setting extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            confirmPassword: "",
            photo: "",
        };

        this.submitHandler = this.submitHandler.bind(this);
    }

    submitHandler() {
        //
    }

    render() {
        return (
            <Layout>
                <div id="setting">
                    <div className="formbox">
                        <div className="left">
                            <div className="photobox">
                                <img
                                    src={Photo1}
                                    className="img-block"
                                    alt="user"
                                />
                                <Button className="btn-block btn-aqua py-less">
                                    Upload
                                </Button>
                            </div>
                        </div>
                        <div className="right">
                            <div className="email-box">
                                <Input
                                    type="email"
                                    className="dark-1 block ph-white"
                                    placeholder="Update Email"
                                    value={this.state.email}
                                    button={{
                                        text: "Update",
                                        onClick: this.submitHandler,
                                    }}
                                />
                            </div>
                            <div className="password-box">
                                <Input
                                    type="password"
                                    className="dark-1 block ph-white"
                                    placeholder="New Password"
                                    value={this.state.password}
                                />
                                <Input
                                    type="password"
                                    className="dark-1 block ph-white"
                                    placeholder="Confirm Password"
                                    value={this.state.confirmPassword}
                                    button={{
                                        text: "Update",
                                        onClick: this.submitHandler,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="submit-button">
                        <Button className="btn-lg btn-orange btn-capitalized">
                            Update
                        </Button>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default Setting;
