import React from "react";
import './ChallengeDetails.scss';
import ChallengeTitle from "../../../components/ChallengeTitle/ChallengeTitle";
import ModalLayout from "../../../components/ModalLayout/ModalLayout";
import UserList from "../../../components/UserList/UserList";
import UserPhoto from "../../../assets/images/users/user-1.jpg";
import Live from "../../../assets/images/icons/Live.png";

class ChallengeDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            participants: [
                {
                    id: 1,
                    name: "Jonathan Morrison",
                    photo: UserPhoto,
                    views: '250K',
                    votes: 4987,
                    amount: 500
                },
                {
                    id: 1,
                    name: "Jonathan Morrison",
                    photo: UserPhoto,
                    views: '250K',
                    votes: 4987,
                    amount: 500
                },
            ],
            viewers: [
                {
                    id: 1,
                    name: "Jonathan Morrison",
                    photo: UserPhoto,
                    amount: 500,
                    voted: "Linus Tech"
                },
                {
                    id: 1,
                    name: "Jonathan Morrison",
                    photo: UserPhoto,
                    amount: 500,
                    voted: "Linus Tech"
                },
            ],
        };
    }

    render() {
        return (
            <ModalLayout closeHandler={this.props.closeHandler}>
                <div className="challenge-details">
                    <div className="top">
                        <ChallengeTitle challenge={this.props.challenge} />
                        <div className="live">
                            <img src={Live} alt="live"/>
                        </div>
                    </div>
                    <div className="bottom">
                        <div className="participants">
                            <UserList
                                users={this.state.participants}
                                title="List of Participants"
                            />
                        </div>
                        <div className="viewers">
                            <UserList
                                users={this.state.viewers}
                                title="List of Viewers"
                                singleLabel
                            />
                        </div>
                    </div>
                </div>
            </ModalLayout>
        );
    }
}

export default ChallengeDetails;
