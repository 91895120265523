import React from 'react';
import {Link} from 'react-router-dom';
import './Title.scss';

const Title = (props) => {
    return ( <div className="d-title">
        <h3 className="text">{props.text}</h3>
        <div className="bar"></div>
        <Link className="link" to={props.more}>See more</Link>
    </div> );
}

export default Title;
