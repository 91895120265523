import React from "react";
import './DataBox.scss';

const DataBox = (props) => {
    let action;

    if (props.actionText) {
        action = <div onClick={props.action} className="action">{props.actionText}</div>;
    }

    return (
        <div className={"databox " + (props.className ? props.className : '')}>
            <h3 className="title light-heading">{props.title}</h3>
            <p className="value">{props.value}</p>
            {action}
        </div>
    );
};

export default DataBox;
