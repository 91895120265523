import React from "react";
import "./MailBox.scss";
import Button from "../UI/Button/Button";
import Photo1 from "../../assets/images/users/user-1.jpg";

class MailBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mails: [
                {
                    id: 1,
                    text:
                        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis dolor fugiat velit eius dicta blanditiis, deleniti illum impedit. Incidunt, distinctio! Iusto quam nostrum unde. Sint eaque officia itaque eum ab?\n\nLorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis dolor fugiat velit eius dicta blanditiis.\n\nBest Regards,\nJohn Smith",
                    category: "Meeting",
                    date: "Sun, Apr 17, 2011",
                    time: "10:15 PM",
                    sender: {
                        name: "John Doe",
                        photo: Photo1,
                    },
                    attachments: [
                        {
                            name: "Wireframe",
                            size: "650KB",
                        },
                        {
                            name: "Wireframe",
                            size: "650KB",
                        },
                    ],
                },
            ],
            selected: null
        };
    }

    componentWillMount(){
        this.setState((state) => ({
            selected: state.mails[0]
        }))
    }

    render() {
        const attachments = this.state.selected.attachments.map((attachment) => (
            <div className="attachment">
                <div className="left">
                    <span className="name">{attachment.name}</span>
                    <span className="size">({attachment.size})</span>
                </div>
                <div className="right">
                    <Button className="btn-sm">Download</Button>
                </div>
            </div>
        ));

        const text = this.state.selected.text;

        return (
            <div className="mailbox">
                <div className="info">
                    <div className="user">
                        <div className="photo">
                            <img
                                src={this.state.selected.sender.photo}
                                className="img-block"
                                alt="sender"
                            />
                        </div>
                        <div className="detail">
                            <h3 className="name">
                                {this.state.selected.sender.name}
                            </h3>
                            <h4 className="category">
                                {this.state.selected.category}
                            </h4>
                        </div>
                    </div>
                    <div className="date">
                        {this.state.selected.date} <br /> {this.state.selected.time}
                    </div>
                </div>
                <div className="text">{text}</div>
                <div className="attachment-container">{attachments}</div>
            </div>
        );
    }
}

export default MailBox;
