import React from "react";
import "./User.scss";
import Label from "../../Label/Label";
import DollarIconWhite from "../../../assets/images/icons/DollarIconWhite.png";
import VotesIcon from "../../../assets/images/icons/VotesIcon.png";
import ViewsIcon from "../../../assets/images/icons/ViewsIcon.png";
import DollarIcon from "../../../assets/images/icons/DollarIcon.png";

const User = (props) => {
    let label, subtitle;

    if (!props.hideLabel) {
        if (props.singleLabel) {
            label = (
                <Label
                    className="userbox"
                    text={"$" + props.user.amount}
                    icon={DollarIcon}
                />
            );
        } else {
            label = (
                <div>
                    <Label
                        className="small"
                        text={props.user.views}
                        icon={ViewsIcon}
                    />
                    <Label
                        className="small"
                        text={props.user.votes}
                        icon={VotesIcon}
                    />
                    <Label
                        className="small"
                        text={props.user.amount}
                        icon={DollarIconWhite}
                    />
                </div>
            );
        }
    }

    if (props.user.voted) {
        subtitle = (
            <h6 className="subtitle">
                <span>Voted For : </span>
                {props.user.voted}
            </h6>
        );
    }

    return (
        <div className="user-component">
            <div className="left">
                <div className="photo">
                    <img
                        src={props.user.photo}
                        className="img-block"
                        alt="user"
                    />
                </div>
            </div>
            <div className="middle">
                <h5 className="name">{props.user.name}</h5>
                {subtitle}
            </div>
            <div className="right">{label}</div>
        </div>
    );
};

export default User;
