import React from "react";
import "./Input.scss";
import Arrow from "../../../assets/images/icons/DownArrow.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from "../Button/Button";

class Input extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: "",
            isFocused: false,
        };

        this.toggleFocusHandler = this.toggleFocusHandler.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
    }

    toggleFocusHandler() {
        this.setState((state) => ({
            isFocused: !state.isFocused,
        }));
    }

    changeHandler(e) {
        const value = e.target ? e.target.value : e;
        this.setState({
            value: value,
        });
        if (this.props.onChange) {
            this.props.onChange(value);
        }
    }

    dropdownHandler(value) {
        this.setState({
            value: value,
        });
        this.changeHandler(value);
    }

    render() {
        let icon, dropdown, field, button;

        if (this.props.type === "datetime") {
            field = (
                <DatePicker
                    value={this.state.value.toString()}
                    placeholderText={this.props.placeholder}
                    className="input"
                    minDate={new Date()}
                    onChange={this.changeHandler}
                    showTimeSelect
                />
            );
        } else if (this.props.type === "textarea") {
            field = (
                <textarea
                    className="input"
                    type={this.props.type}
                    name={this.props.name}
                    placeholder={this.props.placeholder}
                    onChange={this.changeHandler}
                >
                    {this.state.value}
                </textarea>
            );
        } else {
            field = (
                <input
                    className="input"
                    type={this.props.type}
                    value={this.state.value}
                    name={this.props.name}
                    placeholder={this.props.placeholder}
                    onChange={this.changeHandler}
                    readOnly={this.props.type === "dropdown"}
                />
            );
        }

        if (this.props.button) {
            button = (
                <Button
                    className="btn-green btn-input"
                    onClick={this.props.button.onClick}
                >
                    {this.props.button.text}
                </Button>
            );
        }

        if (this.props.icon) {
            icon = (
                <div className="icon">
                    <img src={this.props.icon} alt="icon" />
                </div>
            );
        }

        if (this.props.type === "dropdown") {
            let dropdownItems;

            if (this.state.isFocused) {
                dropdownItems = (
                    <ul className="item-list">
                        {this.props.items.map((item) => (
                            <li
                                key={item.value}
                                onClick={this.dropdownHandler.bind(
                                    this,
                                    item.value
                                )}
                            >
                                {item.text}
                            </li>
                        ))}
                    </ul>
                );
            }

            dropdown = (
                <div className="input-dropdown">
                    <div className="trigger">
                        <img src={Arrow} alt="arrow" />
                    </div>
                    {dropdownItems}
                </div>
            );
        }

        return (
            <div
                className={
                    "input-group " +
                    this.props.className +
                    (this.props.icon ? "" : " without-icon")
                }
                onClick={this.toggleFocusHandler}
            >
                {icon}
                {field}
                {button}
                {dropdown}
            </div>
        );
    }
}

export default Input;
