import React from 'react';
import './BigSelector.scss';

const BigSelector = (props) => {
    return (
        <div onClick={props.onClick} className={"bigselector " + (props.active ? 'active' : '')}>
            {props.text}
        </div>
     );
}

export default BigSelector;
