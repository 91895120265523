import React from "react";
import "./ComposeBox.scss";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

class ComposeBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            to: "",
            Subject: "",
            editorState: EditorState.createEmpty(),
        };

        this.onEditorStateChange = this.onEditorStateChange.bind(this);
    }

    onEditorStateChange(editorState) {
        this.setState({
            editorState,
        });
    }

    render() {
        return (
            <div className="composebox">
                <div className="input-group">
                    <span>To:</span>
                    <input type="email" />
                </div>
                <div className="input-group">
                    <span>Subject:</span>
                    <input type="text" />
                </div>
                <div className="wysiwyg">
                    <Editor
                        editorState={this.state.editorState}
                        toolbarClassName="toolbar"
                        wrapperClassName="wrapper"
                        editorClassName="editor"
                        onEditorStateChange={this.onEditorStateChange}
                    />
                </div>
            </div>
        );
    }
}

export default ComposeBox;
