import React from "react";
import "./MessageBox.scss";
import MessageIcon from "../../../assets/images/icons/MessageIcon.png";
import {withRouter} from "react-router-dom";

class MessageBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            messages: null,
            isUnread: true,
        };

        this.ClickHandler = this.ClickHandler.bind(this);
    }

    ClickHandler() {
        this.setState({
            isUnread: false,
        });
        this.props.history.push('/mail')
    }

    render() {
        let unreadIndicator;

        if (this.state.isUnread) {
            unreadIndicator = <span className="unreadIndicator"></span>;
        }

        return (
            <div className="messagebox" onClick={this.ClickHandler}>
                <img src={MessageIcon} alt="Messages" className="icon img-fluid" />
                {unreadIndicator}
            </div>
        );
    }
}

export default withRouter(MessageBox);
