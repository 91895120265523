import React from "react";
import "./ReportIndex.scss";
import Layout from "../../components/Layout/Layout";
import ScrollBox from "../../components/ScrollBox/ScrollBox";
import DropdownTrigger from "../../components/DropdownTrigger/DropdownTrigger";
import Dropdown from "../../components/Dropdown/Dropdown";
import Report from "../../components/Report/Report";
import UserPhoto from "../../assets/images/users/user-1.jpg";
import Video1 from "../../assets/images/thumbnails/video-1.jpg";

class ReportIndex extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            reports: [
                {
                    id: 1,
                    title: "Title of the reported media",
                    details:
                        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolorum sequi iure esse ducimus beatae consequuntur, voluptatum dolorem totam magnam corporis a cupiditate, autem labore mollitia dicta magni minus dolores eos.",
                    media: "Macbook unbox",
                    thumbnail: Video1,
                    user: {
                        name: "Asiwome Smith",
                        photo: UserPhoto,
                    },
                    reporter: {
                        name: "Linus Tech",
                    },
                },
                {
                    id: 2,
                    title: "Title of the reported media",
                    details:
                        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolorum sequi iure esse ducimus beatae consequuntur, voluptatum dolorem totam magnam corporis a cupiditate, autem labore mollitia dicta magni minus dolores eos.",
                    media: "Macbook unbox",
                    thumbnail: Video1,
                    user: {
                        name: "Asiwome Smith",
                        photo: UserPhoto,
                    },
                    reporter: {
                        name: "Linus Tech",
                    },
                },
                {
                    id: 3,
                    title: "Title of the reported media",
                    details:
                        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolorum sequi iure esse ducimus beatae consequuntur, voluptatum dolorem totam magnam corporis a cupiditate, autem labore mollitia dicta magni minus dolores eos.",
                    media: "Macbook unbox",
                    thumbnail: Video1,
                    user: {
                        name: "Asiwome Smith",
                        photo: UserPhoto,
                    },
                    reporter: {
                        name: "Linus Tech",
                    },
                },
                {
                    id: 4,
                    title: "Title of the reported media",
                    details:
                        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolorum sequi iure esse ducimus beatae consequuntur, voluptatum dolorem totam magnam corporis a cupiditate, autem labore mollitia dicta magni minus dolores eos.",
                    media: "Macbook unbox",
                    thumbnail: Video1,
                    user: {
                        name: "Asiwome Smith",
                        photo: UserPhoto,
                    },
                    reporter: {
                        name: "Linus Tech",
                    },
                },
            ],
            dropdown: {
                isVisible: false,
                items: [
                    {
                        text: "Challenge",
                    },
                    {
                        text: "Stream",
                    },
                    {
                        text: "Profile",
                    },
                    {
                        text: "User",
                    },
                ],
            },
        };

        this.toggleDropdownHandler = this.toggleDropdownHandler.bind(this);
    }

    toggleDropdownHandler() {
        this.setState((state) => ({
            dropdown: {
                ...state.dropdown,
                isVisible: !state.dropdown.isVisible,
            },
        }));
    }

    render() {
        let dropdown, reports;

        if (this.state.dropdown.isVisible) {
            dropdown = (
                <Dropdown
                    className="light"
                    CloseHandler={this.toggleDropdownHandler}
                    items={this.state.dropdown.items}
                />
            );
        }

        reports = this.state.reports.map((report) => (
            <Report key={report.id} report={report} />
        ));

        return (
            <Layout>
                <div id="report-index">
                    <div className="title">
                        <h2>Reported media</h2>
                        <div className="dropdown-container">
                            <button onClick={this.toggleDropdownHandler}>
                                Stream
                                <DropdownTrigger />
                            </button>
                            {dropdown}
                        </div>
                    </div>
                    <div className="report-container">
                        <ScrollBox height="45rem">
                            {reports}
                        </ScrollBox>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default ReportIndex;
