import React from "react";
import Layout from "../../../components/Layout/Layout";
import BigSelector from "../../../components/UI/BigSelector/BigSelector";
import Input from "../../../components/UI/Input/Input";
import Button from "../../../components/UI/Button/Button";
import "./UserCreate.scss";

class UserCreate extends React.Component {
    constructor() {
        super();
        this.state = {
            UserType: "admin",
            FormData: {
                name: "",
                email: "",
                age: "",
                password: "",
            },
        };

        this.ChangeHandler = this.ChangeHandler.bind(this);
        this.SubmitHandler = this.SubmitHandler.bind(this);
    }

    SwitchHandler(type) {
        this.setState({
            UserType: type,
        });
    }

    ChangeHandler(event) {
        const insert = {
            [event.target.attributes.name.value]: event.target.value,
        };

        this.setState((state) => ({
            FormData: {
                ...state.FormData,
                ...insert,
            },
        }));
    }

    SubmitHandler() {

    }

    render() {
        return (
            <Layout>
                <div className="form-container">
                    <div className="selectorbox">
                        <BigSelector
                            onClick={this.SwitchHandler.bind(this, "admin")}
                            text="Create Admin"
                            active={this.state.UserType === "admin"}
                        />
                        <BigSelector
                            onClick={this.SwitchHandler.bind(this, "user")}
                            text="Create User"
                            active={this.state.UserType === "user"}
                        />
                    </div>
                    <div className="form">
                        <Input
                            placeholder="Name"
                            type="text"
                            onChange={this.ChangeHandler}
                            name="name"
                            className="block py-less"
                            value={this.state.FormData.name}
                        />
                        <Input
                            placeholder="Email"
                            type="email"
                            onChange={this.ChangeHandler}
                            name="email"
                            className="block py-less"
                            value={this.state.FormData.email}
                        />
                        <Input
                            placeholder="Age"
                            type="number"
                            onChange={this.ChangeHandler}
                            name="age"
                            className="block py-less"
                            value={this.state.FormData.age}
                        />
                        <Input
                            placeholder="Password"
                            type="password"
                            onChange={this.ChangeHandler}
                            name="password"
                            className="block py-less"
                            value={this.state.FormData.password}
                        />
                    </div>
                    <div className="button-container text-center">
                        <Button
                            onClick={this.SubmitHandler}
                            className="btn-orange btn-lg text-capitalize py-less"
                        >
                            Create
                        </Button>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default UserCreate;
