import React from "react";
import "./UserList.scss";
import SearchBox from "../SearchBox/SearchBox";
import User from "./User/User";

class UserList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            search: "",
        };
    }
    render() {
        const users = this.props.users.map((user) => (
            <User singleLabel={this.props.singleLabel} user={user} />
        ));

        return (
            <div className="userlist">
                <div className="top">
                    <h4 className="title">{this.props.title}</h4>
                    <SearchBox className="small" />
                </div>
                <div className="bottom">{users}</div>
            </div>
        );
    }
}

export default UserList;
