import React from "react";
import "./MailBar.scss";
import Dropdown from "../../components/Dropdown/Dropdown";
import DropdownTrigger from "../../components/DropdownTrigger/DropdownTrigger";

class MailBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isDropdownVisible: false,
        };

        this.toggleDropdownHandler = this.toggleDropdownHandler.bind(this);
    }

    toggleDropdownHandler() {
        this.setState((state) => ({
            isDropdownVisible: !state.isDropdownVisible,
        }));
    }

    render() {
        let badge, dropdown;

        if (this.props.mail.isNew) {
            badge = <div className="badge new">New</div>;
        } else {
            badge = <div className="badge date">{this.props.mail.date}</div>;
        }

        if (this.state.isDropdownVisible) {
            dropdown = (
                <Dropdown
                    className="light"
                    CloseHandler={this.toggleDropdownHandler}
                    items={[
                        {
                            text: "Mark As Read",
                        },
                        {
                            text: "Reply",
                        },
                        {
                            text: "Reply All",
                        },
                        {
                            text: "Delete",
                        },
                    ]}
                />
            );
        }

        return (
            <div
                className={
                    "mail-comp " +
                    (this.props.unread ? "unread " : "") +
                    (this.props.active ? "active" : "")
                }
                onClick={this.props.onClick}
            >
                <div className="left">
                    <div className="photo">
                        <img
                            src={this.props.mail.photo}
                            className="img-block"
                            alt="user"
                        />
                    </div>
                </div>
                <div className="right">
                    <h4 className="name">{this.props.mail.sender}</h4>
                    <div className="category">{this.props.mail.category}</div>
                    <p className="excerpt">{this.props.mail.excerpt}</p>
                </div>
                {badge}
                <div className="dropdown-box">
                    <DropdownTrigger onClick={this.toggleDropdownHandler} />
                    {dropdown}
                </div>
            </div>
        );
    }
}

export default MailBar;
