import React from "react";
import DataBox from '../DataBox/DataBox';
import Thumbnail from '../Thumbnail/Thumbnail';
import './Report.scss';
import Button from "../UI/Button/Button";

const Report = (props) => {
    return (
        <div className="report">
            <div className="left">
                <div className="user">
                    <img src={props.report.user.photo} alt="user" />
                    <h5>{props.report.user.name}</h5>
                </div>
                <div className="text">
                    <h3 className="title">{props.report.title}</h3>
                    <p>{props.report.details}</p>
                </div>
            </div>
            <div className="right">
                <div className="infobox">
                    <DataBox className="title-orange light" title="Reporter" value={props.report.reporter.name} />
                    <DataBox className="title-orange light" title="Media" value={props.report.media} />
                </div>
                <div className="thumbnail-box">
                    <Thumbnail photo={props.report.thumbnail} />
                </div>
                <div className="button-box">
                    <Button className="btn-sm btn-green">Accept</Button>
                    <Button className="btn-sm btn-orange">Decline</Button>
                    <Button className="btn-sm btn-aqua">View Media</Button>
                    <Button className="btn-sm btn-purple">Message</Button>
                </div>
            </div>
        </div>
    );
};

export default Report;
