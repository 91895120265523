import React from 'react';
import './UserPhoto.scss';

const UserPhoto = (props) => {
    return (
        <div className="userphoto">
            <span className={"status" + (props.isOnline ? " online" : "")}></span>
            <div className="imagebox">
                <img src={props.image} className="img-block" alt="User"/>
            </div>
            <div className="flagbox">
                <img className="img-fluid" src={props.flag} alt="flag"/>
            </div>
        </div>
     );
}

export default UserPhoto;
