import React from "react";
import { Link } from "react-router-dom";
import "./Sidebar.scss";
import SidebarLink from "./SidebarLink/SidebarLink";
import HomeIcon from "../../assets/images/icons/HomeIcon.png";
import HomeIconRed from "../../assets/images/icons/HomeIconRed.png";
import ReportIcon from "../../assets/images/icons/ReportIcon.png";
import ReportIconRed from "../../assets/images/icons/ReportIconRed.png";
import UserIcon from "../../assets/images/icons/UserIcon.png";
import UserIconRed from "../../assets/images/icons/UserIconRed.png";
import ChallengeIcon from "../../assets/images/icons/ChallengeIcon.png";
import ChallengeIconRed from "../../assets/images/icons/ChallengeIconRed.png";
import StreamIcon from "../../assets/images/icons/StreamIcon.png";
import StreamIconRed from "../../assets/images/icons/StreamIconRed.png";
import PlusIcon from "../../assets/images/icons/PlusIcon.png";
import PlusIconRed from "../../assets/images/icons/PlusIconRed.png";
import LogoShape from "../../assets/images/Logo-Shape.png";

const Sidebar = (props) => {
    const links = [
        {
            icon: HomeIcon,
            activeIcon: HomeIconRed,
            text: "Home",
            target: "/dashboard",
        },
        {
            icon: UserIcon,
            activeIcon: UserIconRed,
            text: "User",
            target: "/users",
        },
        {
            icon: StreamIcon,
            activeIcon: StreamIconRed,
            text: "Streams",
            target: "/streams",
        },
        {
            icon: ChallengeIcon,
            activeIcon: ChallengeIconRed,
            text: "Challenges",
            target: "/challenges",
        },
        {
            icon: ReportIcon,
            activeIcon: ReportIconRed,
            text: "Report",
            target: "/reports",
        },
        {
            icon: PlusIcon,
            activeIcon: PlusIconRed,
            text: "Add User",
            target: "/users/create",
        },
    ];

    return (
        <div id="sidebar">
            <div className="top">
                <Link to="/dashboard">
                    <img src={LogoShape} alt="Logo" />
                </Link>
            </div>
            <div className="middle">
                {links.map(({ activeIcon, icon, text, target }) => (
                    <SidebarLink
                        key={text}
                        activeIcon={activeIcon}
                        icon={icon}
                        text={text}
                        target={target}
                    />
                ))}
            </div>
        </div>
    );
};

export default Sidebar;
