import React from "react";
import "./AreaGraph.scss";
import {
    AreaChart,
    Area,
    Tooltip,
    ResponsiveContainer,
} from "recharts";
import Dropdown2 from "../../Dropdown2/Dropdown2";

const AreaGraph = (props) => {
    return (
        <div className="graph">
            <div className="title">{props.title}</div>
            <div className="dropdown-container">
                <Dropdown2
                    orange={props.orange}
                    title={props.dropdown.title}
                    changeHandler={props.updateData}
                    items={props.dropdown.items}
                />
            </div>
            <div className="badge">
                <span className="prefix">{props.prefix}</span>
                {props.badge}
            </div>
            <ResponsiveContainer height={150} width="100%">
                <AreaChart
                    data={props.data}
                    margin={{
                        top: 0,
                        right: 0,
                        left: 0,
                        bottom: 0,
                    }}
                >
                    <Tooltip />
                    <Area
                        type="monotone"
                        dataKey="uv"
                        stroke={props.color}
                        fill={props.color}
                    />
                </AreaChart>
            </ResponsiveContainer>
        </div>
    );
};

export default AreaGraph;
