import React from "react";
import "./UserRow.scss";
import CheckGreen from "../../assets/images/icons/CheckGreen.png";
import CrossRed from "../../assets/images/icons/CrossRed.png";
import UserPhoto from "../UserPhoto/UserPhoto";
import Dropdown from "../Dropdown/Dropdown";
import LockIcon from "../../assets/images/icons/lock.png";
import MessageIcon from "../../assets/images/icons/message.png";
import BlockIcon from "../../assets/images/icons/block.png";
import DropdownTrigger from "../DropdownTrigger/DropdownTrigger";
import {withRouter} from 'react-router-dom';

class UserRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            DropdownVisible: false,
        };

        this.closeDropdownHandler = this.closeDropdownHandler.bind(this);
        this.openDropdownHandler = this.openDropdownHandler.bind(this);
    }

    closeDropdownHandler() {
        this.setState({
            DropdownVisible: false,
        });
    }

    openDropdownHandler() {
        this.setState({
            DropdownVisible: true
        })
    }

    render() {
        let dropdown;

        if (this.state.DropdownVisible) {
            dropdown = (
                <Dropdown
                    className="light"
                    CloseHandler={this.closeDropdownHandler}
                    items={[
                        {
                            text: "Reset Password",
                            icon: LockIcon,
                        },
                        {
                            text: "Send Email",
                            icon: MessageIcon,
                        },
                        {
                            text: "Block User",
                            icon: BlockIcon,
                        },
                    ]}
                />
            );
        }

        return (
            <div className="user-row">
                <div className="col-user" onClick={() => this.props.history.push('/users/'+this.props.id)}>
                    <div className="left">
                        <UserPhoto
                            image={this.props.photo}
                            flag={this.props.flag}
                            isOnline={this.props.isOnline}
                        />
                    </div>
                    <div className="right">
                        <h3 className="light-heading">{this.props.name}</h3>
                        <span className="email">{this.props.email}</span>
                    </div>
                </div>
                <div className="col col-dropdown">
                    <h5 className="title">Date Registered</h5>
                    <span className="data">{this.props.registerDate}</span>
                </div>
                <div className="col">
                    <h5 className="title"># of follower</h5>
                    <span className="data">{this.props.follower}</span>
                </div>
                <div className="col">
                    <h5 className="title"># of following</h5>
                    <span className="data">{this.props.following}</span>
                </div>
                <div className="col">
                    <h5 className="title">Amount Spent</h5>
                    <span className="data">${this.props.amountSpent}</span>
                </div>
                <div className="col">
                    <h5 className="title">Amount Generated</h5>
                    <span className="data">${this.props.follower}</span>
                </div>
                <div className="col email">
                    <h5 className="title">Verified email</h5>
                    <span className="data">
                        <img
                            className="icon"
                            src={
                                this.props.isEmailVerified
                                    ? CheckGreen
                                    : CrossRed
                            }
                            alt="icon"
                        />
                    </span>
                </div>
                <div className="row-dropdown">
                    <DropdownTrigger onClick={this.openDropdownHandler} />
                    <div className="dropdown-container">{dropdown}</div>
                </div>
            </div>
        );
    }
}

export default withRouter(UserRow);
