import React from "react";
import "./ModalLayout.scss";

const ModalLayout = (props) => {
    return (
        <div className={"modal " + (props.notice ? "notice" : "")}>
            <div className="modal-body">
                <div
                    onClick={props.closeHandler}
                    className="close-trigger"
                ></div>
                {props.children}
            </div>
        </div>
    );
};

export default ModalLayout;
