import React from "react";
import "./Video.scss";
import Label from "../Label/Label";
import EyeIcon from "../../assets/images/icons/EyeIcon.png";
import DollarIcon from "../../assets/images/icons/DollarIcon.png";
import Thumbnail from "../Thumbnail/Thumbnail";
import UsersModal from "../../containers/User/UsersModal/UsersModal";
import {withRouter} from 'react-router-dom';

class Video extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ViewersModalVisible: false,
        };

        this.toggleViewersHandler = this.toggleViewersHandler.bind(this);
        this.clickHandler = this.clickHandler.bind(this);
    }

    toggleViewersHandler() {
        if (!this.props.noViewersModal) {
            this.setState((state) => ({
                ViewersModalVisible: !state.ViewersModalVisible,
            }));
        }
    }

    clickHandler() {
        if (this.props.noViewersModal) {
            this.props.history.push(`/users/${this.props.video.user}/videos/${this.props.video.id}`)
        }
    }

    render() {
        let viewers, date;

        if (this.props.video.created_at) {
            date = (
                <span className="date">
                    Added {this.props.video.created_at}
                </span>
            );
        }

        if (this.state.ViewersModalVisible) {
            viewers = (
                <UsersModal
                    closeHandler={this.toggleViewersHandler}
                    video={this.props.video}
                    title="Viewers"
                />
            );
        }

        return (
            <div onClick={this.clickHandler}>
                <div
                    className={
                        "video " +
                        (this.props.className ? this.props.className : "")
                    }
                >
                    <Thumbnail photo={this.props.video.thumbnail} />
                    {date}
                    <div className="infobox">
                        <h4 className="title">{this.props.video.title}</h4>
                    </div>
                    <div className="labelbox">
                        <Label
                            onClick={this.toggleViewersHandler}
                            icon={EyeIcon}
                            text="1500"
                        />
                        <Label icon={DollarIcon} text="$800" />
                    </div>
                </div>
                {viewers}
            </div>
        );
    }
}

export default withRouter(Video);
