import React from "react";
import User from "../../../assets/images/icons/User.png";
import Arrow from "../../../assets/images/icons/DownArrow.png";
import "./UserBox.scss";
import Dropdown from "../../Dropdown/Dropdown";
import { withRouter } from "react-router-dom";

class UserBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showDropdown: false,
            dropdownItems: [
                {
                    text: "Setting",
                    onClick: () => this.props.history.push("/setting"),
                },
                {
                    text: "Logout",
                    onClick: () => this.props.history.push("/auth/login"),
                },
            ],
            user: {
                name: "John Smith",
                photo: User,
            },
        };

        this.ToggleDropdownHandler = this.ToggleDropdownHandler.bind(this);
    }

    ToggleDropdownHandler() {
        this.setState((state) => ({
            showDropdown: !state.showDropdown,
        }));
    }

    render() {
        let dropdown;

        if (this.state.showDropdown) {
            dropdown = (
                <Dropdown
                    CloseHandler={this.ToggleDropdownHandler}
                    items={this.state.dropdownItems}
                />
            );
        }

        return (
            <div className="userbox">
                <div className="image">
                    <img
                        src={this.state.user.photo}
                        alt={this.state.user.name}
                    />
                </div>
                <div className="name">
                    <h4>{this.state.user.name}</h4>
                </div>
                <div className="arrow" onClick={this.ToggleDropdownHandler}>
                    <img src={Arrow} alt="arrow" />
                </div>
                {dropdown}
            </div>
        );
    }
}

export default withRouter(UserBox);
