import React from "react";
import "./App.scss";
import Auth from "./containers/Auth/Auth";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from "react-router-dom";
import UserCreate from "./containers/User/UserCreate/UserCreate";
import UserIndex from "./containers/User/UserIndex/UserIndex";
import UserDetails from "./containers/User/UserDetails/UserDetails";
import UserVideos from "./containers/User/UserVideos/UserVideos";
import ReportIndex from "./containers/Report/ReportIndex";
import ChallengeIndex from "./containers/Challenge/ChallengeIndex/ChallengeIndex";
import StreamIndex from "./containers/Stream/StreamIndex/StreamIndex";
import NotificationIndex from "./containers/Notification/NotificationIndex/NotificationIndex";
import NotificationCreate from "./containers/Notification/NotificationCreate/NotificationCreate";
import Setting from "./containers/Setting/Setting";
import Mail from "./containers/Mail/Mail";
import Dashboard from "./containers/Dashboard/Dashboard";

function App() {
    return (
        <Router>
            <Switch>
                <Route path="/auth">
                    <Auth />
                </Route>

                <Route exact path="/users">
                    <UserIndex />
                </Route>
                <Route exact path="/users/create">
                    <UserCreate />
                </Route>
                <Route exact path="/users/:id">
                    <UserDetails />
                </Route>
                <Route exact path="/users/:id/videos">
                    <UserVideos />
                </Route>
                <Route exact path="/users/:userId/videos/:videoId">
                    <UserVideos />
                </Route>

                <Route exact path="/reports">
                    <ReportIndex />
                </Route>

                <Route exact path="/challenges">
                    <ChallengeIndex />
                </Route>
                <Route exact path="/challenges/:challengeId">
                    <ChallengeIndex />
                </Route>

                <Route exact path="/streams">
                    <StreamIndex />
                </Route>

                <Route exact path="/notifications">
                    <NotificationIndex />
                </Route>
                <Route exact path="/notifications/new">
                    <NotificationCreate />
                </Route>

                <Route exact path="/setting">
                    <Setting />
                </Route>

                <Route exact path="/mail">
                    <Mail />
                </Route>

                <Route exact path="/dashboard">
                    <Dashboard />
                </Route>

                <Route exact path="/">
                    <Redirect to="/auth/login" />
                </Route>
            </Switch>
        </Router>
    );
}

export default App;
