import React from "react";
import "./Button.scss";

const Button = (props) => {
    let icon, badge;

    if (props.icon) {
        icon = <img src={props.icon} alt="icon" />;
    }

    if (props.badge) {
        badge = <span className="badge">{props.badge}</span>;
    }

    return (
        <button onClick={props.onClick} className={"btn " + props.className}>
            {icon}
            {props.children}
            {badge}
        </button>
    );
};

export default Button;
