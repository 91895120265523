import React from "react";
import "./UserVideos.scss";
import Layout from "../../../components/Layout/Layout";
import Photo2 from "../../../assets/images/users/user-2.jpg";
import CanadaFlag from "../../../assets/images/icons/CanadaFlag.png";
import UserBar from "../../../components/UserBar/UserBar";
import Video1 from "../../../assets/images/thumbnails/video-1.jpg";
import Video2 from "../../../assets/images/thumbnails/video-2.jpg";
import Video3 from "../../../assets/images/thumbnails/video-3.jpg";
import Video from "../../../components/Video/Video";
import { withRouter } from "react-router-dom";

class UserVideos extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {
                id: 1,
                name: "Linus Tech",
                email: "Linushtech@gmail.com",
                registerDate: "05-05-2019",
                follower: "12K",
                following: "120",
                amountSpent: "24K",
                generatedRevenue: "24k",
                isOnline: true,
                isEmailVerified: true,
                photo: Photo2,
                flag: CanadaFlag,
                challengeCount: 12,
                liveStreamCount: 20,
                uploadedMediaCount: 15,
            },
            videos: [
                {
                    id: 1,
                    title: "Linus tech Tips on Twitter",
                    view: 1500,
                    amount: 800,
                    thumbnail: Video1,
                    created_at: "2019-10-07",
                },
                {
                    id: 2,
                    title: "Linus tech Tips on Twitter",
                    view: 1500,
                    amount: 800,
                    thumbnail: Video2,
                    created_at: "2019-10-07",
                },
                {
                    id: 3,
                    title: "Linus tech Tips on Twitter",
                    view: 1500,
                    amount: 800,
                    thumbnail: Video3,
                    created_at: "2019-10-07",
                },
                {
                    id: 4,
                    title: "Linus tech Tips on Twitter",
                    view: 1500,
                    amount: 800,
                    thumbnail: Video2,
                    created_at: "2019-10-07",
                },
                {
                    id: 5,
                    title: "Linus tech Tips on Twitter",
                    view: 1500,
                    amount: 800,
                    thumbnail: Video3,
                    created_at: "2019-10-07",
                },
                {
                    id: 6,
                    title: "Linus tech Tips on Twitter",
                    view: 1500,
                    amount: 800,
                    thumbnail: Video1,
                    created_at: "2019-10-07",
                },
                {
                    id: 7,
                    title: "Linus tech Tips on Twitter",
                    view: 1500,
                    amount: 800,
                    thumbnail: Video2,
                    created_at: "2019-10-07",
                },
                {
                    id: 8,
                    title: "Linus tech Tips on Twitter",
                    view: 1500,
                    amount: 800,
                    thumbnail: Video3,
                    created_at: "2019-10-07",
                },
                {
                    id: 9,
                    title: "Linus tech Tips on Twitter",
                    view: 1500,
                    amount: 800,
                    thumbnail: Video2,
                    created_at: "2019-10-07",
                },
                {
                    id: 10,
                    title: "Linus tech Tips on Twitter",
                    view: 1500,
                    amount: 800,
                    thumbnail: Video3,
                    created_at: "2019-10-07",
                },
                {
                    id: 11,
                    title: "Linus tech Tips on Twitter",
                    view: 1500,
                    amount: 800,
                    thumbnail: Video2,
                    created_at: "2019-10-07",
                },
                {
                    id: 12,
                    title: "Linus tech Tips on Twitter",
                    view: 1500,
                    amount: 800,
                    thumbnail: Video3,
                    created_at: "2019-10-07",
                },
            ],
        };
    }

    componentDidMount() {
        if (this.props.match.params.videoId) {
            this.setState({
                videos: [
                    {
                        id: this.props.match.params.videoId,
                        title: "Linus tech Tips on Twitter",
                        view: 1500,
                        amount: 800,
                        thumbnail: Video1,
                        created_at: "2019-10-07",
                    },
                ],
            });
        }
    }

    render() {
        const videos = this.state.videos.map((video) => (
            <Video key={video.id} video={video} />
        ));

        return (
            <Layout overlay>
                <UserBar user={this.state.user} />
                <div className="video-container">{videos}</div>
            </Layout>
        );
    }
}

export default withRouter(UserVideos);
