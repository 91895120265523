import React from "react";
import Layout from "../../../components/Layout/Layout";
import { withRouter } from "react-router-dom";
import Photo2 from "../../../assets/images/users/user-2.jpg";
import CanadaFlag from "../../../assets/images/icons/CanadaFlag.png";
import UserBar from "../../../components/UserBar/UserBar";
import DataBox from "../../../components/DataBox/DataBox";
import "./UserDetails.scss";

class UserDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {
                id: null,
                name: "Linus Tech",
                email: "Linushtech@gmail.com",
                registerDate: "05-05-2019",
                follower: "12K",
                following: "120",
                amountSpent: "24K",
                generatedRevenue: "24k",
                isOnline: true,
                isEmailVerified: true,
                photo: Photo2,
                flag: CanadaFlag,
                challengeCount: 12,
                liveStreamCount: 20,
                uploadedMediaCount: 15,
            },
        };
    }

    componentDidMount() {
        console.log(this.props.match.params.id); // User ID
        this.setState((state) => ({
            user: {
                ...state.user,
                id: this.props.match.params.id,
            },
        }));
    }

    render() {
        return (
            <Layout>
                <UserBar bigVerified user={this.state.user} />
                <div className="databox-container">
                    <DataBox
                        title="Date Registered"
                        value={this.state.user.registerDate}
                    />
                    <DataBox
                        title="Followers"
                        value={this.state.user.follower}
                    />
                    <DataBox
                        title="Following"
                        value={this.state.user.following}
                    />
                    <DataBox
                        title="Amount Spent"
                        value={"$" + this.state.user.amountSpent}
                    />
                </div>
                <div className="databox-container">
                    <DataBox
                        title="Revenue Generated"
                        value={"$" + this.state.user.generatedRevenue}
                    />
                    <DataBox
                        title="Challenges"
                        value={this.state.user.challengeCount}
                        actionText="View Challenges"
                        action={() => this.props.history.push(`/users/${this.state.user.id}/videos`)}
                    />
                    <DataBox
                        title="Live Streams"
                        value={this.state.user.liveStreamCount}
                        actionText="View Streams"
                        action={() => this.props.history.push(`/users/${this.state.user.id}/videos`)}
                    />
                    <DataBox
                        title="Upload Media"
                        value={this.state.user.uploadedMediaCount}
                        actionText="View Uploads"
                        action={() => this.props.history.push(`/users/${this.state.user.id}/videos`)}
                    />
                </div>
            </Layout>
        );
    }
}

export default withRouter(UserDetails);
